import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import CoverReport from '../../components/Roport/CoverReport';
import CoverFairytale from '../../components/Roport/CoverFairytale';
import generalCoverImage from '../../assets/report_user/general_cover.jpg';
import { INSPECTION_REPORT_URL } from '../../utils/urls';
import instance from '../../services/services';


// iOS 및 Safari 감지 + 앱 환경 감지 추가
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = /Android/.test(navigator.userAgent);
const isMobileApp = !!window.ReactNativeWebView; // 앱 환경 감지
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// 동적으로 Report 컴포넌트 로드하는 함수
const loadReportComponent = async (sortNum) => {
    try {
        const ReportModule = await import(`../../pages/Report/Report/${sortNum}`);
        return ReportModule.default;
    } catch (error) {
        console.error(`Report ${sortNum} 로드 실패:`, error);
        return null;
    }
};

// 동적으로 ReportDetail 컴포넌트 로드하는 함수
const loadReportDetailComponent = async (sortNum) => {
    try {
        const ReportDetailModule = await import(`../Report/ReportDetail/${sortNum}`);
        return ReportDetailModule.default;
    } catch (error) {
        console.error(`ReportDetail ${sortNum} 로드 실패:`, error);
        return null;
    }
};

// 차트를 포함한 모든 컴포넌트에 대해 이미지 변환 함수 추가
const renderComponentWithChartImages = async (Component, props, containerDiv) => {
    ReactDOM.render(
        React.createElement(Component, props),
        containerDiv
    );
    
    // 충분한 렌더링 시간 확보
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // 차트 영역 찾기
    const chartContainers = containerDiv.querySelectorAll('.report-graph, .chart-container, .chart-canvas');
    
    // 차트가 있는 영역을 이미지로 변환
    if (chartContainers.length > 0) {
        console.log('차트 컨테이너 발견:', chartContainers.length);
        
        // 각 차트 컨테이너를 이미지로 변환
        for (let i = 0; i < chartContainers.length; i++) {
            try {
                const chartContainer = chartContainers[i];
                // 이미 이미지로 변환되었는지 확인
                if (chartContainer.querySelector('img')) {
                    continue;
                }
                
                const chartCanvas = await html2canvas(chartContainer, {
                    scale: 2,
                    useCORS: true,
                    logging: true,
                    backgroundColor: '#FFFFFF'
                });
                
                // 캔버스를 이미지로 변환
                const chartImageData = chartCanvas.toDataURL('image/png');
                
                // 원래 컨테이너 내용 비우기
                const originalContent = chartContainer.innerHTML;
                chartContainer.innerHTML = '';
                
                // 이미지 삽입
                const img = document.createElement('img');
                img.src = chartImageData;
                img.style.width = '100%';
                img.style.maxWidth = '100%';
                img.style.display = 'block';
                img.alt = '차트 이미지';
                
                chartContainer.appendChild(img);
                console.log(`차트 ${i} 이미지 변환 성공`);
                
                // 만약 이미지가 비어있거나 오류가 있으면 원래 내용으로 복원
                img.onerror = () => {
                    console.error('차트 이미지 로드 실패, 원본으로 복원');
                    chartContainer.innerHTML = originalContent;
                };
            } catch (e) {
                console.error('차트 이미지 변환 오류:', e);
            }
        }
        
        // 변환된 내용을 다시 대기
        await new Promise(resolve => setTimeout(resolve, 500));
    }
    
    return containerDiv;
};

// fetchDataWithHash 함수를 먼저 정의합니다
const fetchDataWithHash = async (hash) => {
    try {
        if (!hash) return null;
        
        console.log('hash 값으로 데이터 요청:', hash);
        const response = await instance.get(`${INSPECTION_REPORT_URL}?hash=${hash}`);
        
        if (response.data && response.data.data) {
            console.log('GET 요청으로 데이터 가져옴:', response.data.data);
            return response.data.data;
        }
        return null;
    } catch (error) {
        console.error('GET 요청 실패:', error);
        return null;
    }
};

// 앱에서 사용할 리포트 정보 강화
const openReportInNewWindow = async (item, title) => {
    try {
        // POST 요청으로 hash 값 가져오기
        const postResponse = await instance.post(INSPECTION_REPORT_URL, {
            report_seq: item.report_seq
        });
        
        if (!postResponse.data || !postResponse.data.data || !postResponse.data.data.hash) {
            throw new Error('유효한 hash 값을 가져오지 못했습니다');
        }
        
        const hashValue = postResponse.data.data.hash;
        
        // 현재 창 URL에 해시값 추가
        const currentUrl = window.location.origin;
        
        // 앱에서는 앱 전용 URL로 이동 (iOS, Android 모두 해당)
        if (window.ReactNativeWebView) {
            // 앱용 URL (app-report 경로 사용)
            const appReportUrl = `${currentUrl}/app-report?hash=${hashValue}&sort_num=${item.sort_num}&goods_type=${item.goods_type}&report_seq=${item.report_seq}`;
            
            console.log('앱에서 리포트 URL 전송:', appReportUrl);
            
            // 앱에 리포트 URL 전달
            window.ReactNativeWebView.postMessage(JSON.stringify({
                key: "EXTERNAL_LINK",
                value: appReportUrl
            }));
            return; // 앱에서는 여기서 함수 종료
        }
        
        // 웹에서는 일반 report URL 사용
        const reportUrl = `${currentUrl}/report?hash=${hashValue}&sort_num=${item.sort_num}&goods_type=${item.goods_type}&report_seq=${item.report_seq}`;
        
        // 웹에서는 기존 방식으로 새창 열기
        const newWindow = window.open(reportUrl, '_blank');
        
        // 새 창이 차단되었는지 확인
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            alert('팝업이 차단되었습니다. 팝업 차단을 해제해주세요.');
            return;
        }
        
        // 현재 페이지의 스타일시트 수집
        const styleSheets = Array.from(document.styleSheets)
            .filter(sheet => {
                try {
                    // CORS 제한이 있는 스타일시트는 제외
                    return sheet.cssRules && sheet.cssRules.length > 0;
                } catch (e) {
                    return false;
                }
            });
        
        // 스타일 규칙 추출
        let cssText = '';
        styleSheets.forEach(sheet => {
            try {
                Array.from(sheet.cssRules).forEach(rule => {
                    cssText += rule.cssText + '\n';
                });
            } catch (e) {
                console.log('스타일시트 규칙 접근 오류:', e);
            }
        });
        
        // 새 창에 기본 HTML 구조 작성
        newWindow.document.write(`
            <!DOCTYPE html>
            <html>
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">
            <title>${title} 보고서</title>
            <style>
                ${cssText}
                
                body {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
                margin: 0;
                padding: 0;
                background: #fff;
                }
                
                .report-header {
                position: sticky;
                top: 0;
                width: 100%;
                padding: 15px;
                background: #f8f9fa;
                text-align: center;
                box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                z-index: 100;
                }
                
                .report-content {
                max-width: 800px;
                margin: 0 auto;
                background: white;
                }
                
                .report-section {
                margin-bottom: 30px;
                page-break-after: always;
                }
                
                .report-section img {
                max-width: 100%;
                height: auto;
                }
                
                .btn {
                margin: 5px;
                padding: 8px 16px;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                font-size: 14px;
                }
                
                .btn-print {
                background: #28a745;
                }
                
                .btn-close {
                background: #4a90e2;
                }
                
                /* 차트 및 그래프 영역 보존 */
                canvas, svg, 
                .chart-container, [class*='chart'] {
                max-width: 100% !important;
                display: block !important;
                }
                
                /* 기존 UI에 영향을 줄 수 있는 스타일 재정의 */
                .forward_type, .my_list, .assessment_info, .assessment_details, 
                .assessment_title, .assessment_meta, .group_line, 
                .assessment_code, .group_item {
                display: block;
                width: auto;
                margin: initial;
                padding: initial;
                }
                
                /* 모든 폰트 크기 보존 */
                * {
                font-size: inherit;
                }
                
                @media print {
                .report-header {
                    display: none;
                }
                
                body {
                    margin: 0;
                }
                }
                
                /* Chart.js 관련 스타일 추가 */
                canvas.chartjs-render-monitor {
                  display: block !important;
                  width: 100% !important;
                  height: auto !important;
                }
                
                .chartjs-size-monitor, 
                .chartjs-size-monitor-expand, 
                .chartjs-size-monitor-shrink {
                  position: absolute;
                  direction: ltr;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  overflow: hidden;
                  pointer-events: none;
                  visibility: hidden;
                  z-index: -1;
                }
            </style>
            </head>
            <body>
            <div class="report-header">
                <h2>${title} 보고서</h2>
                <p>보고서를 저장하려면 우측 상단 공유 버튼을 누르거나 스크린샷을 찍으세요.</p>
            </div>
            <div class="report-content" id="report-content">
                <div class="loading">보고서를 로딩 중입니다...</div>
            </div>
            </body>
            </html>
        `);
        
        // 모든 스크립트를 새 창에 복사
        Array.from(document.scripts)
            .filter(script => script.src && !script.src.includes('chrome-extension'))
            .forEach(script => {
                const scriptElement = newWindow.document.createElement('script');
                scriptElement.src = script.src;
                scriptElement.async = true;
                newWindow.document.head.appendChild(scriptElement);
            });
        
        // Chart.js를 명시적으로 추가 (전체 번들)
        const chartScript = newWindow.document.createElement('script');
        chartScript.src = 'https://cdn.jsdelivr.net/npm/chart.js@3.9.1/dist/chart.min.js';
        chartScript.integrity = 'sha256-+8RZJLPvbQThHvNR4MY8utaZ5SnxPUxS3yAEWAZiQlk=';
        chartScript.crossOrigin = 'anonymous';
        chartScript.onload = function() {
            const loadEvent = newWindow.document.createElement('script');
            loadEvent.textContent = `window.postMessage({type: 'CHARTJS_LOADED'}, '*');`;
            newWindow.document.body.appendChild(loadEvent);
        };
        newWindow.document.head.appendChild(chartScript);
        
        const contentDiv = newWindow.document.getElementById('report-content');
        
        try {
            // 기본 스타일을 적용한 임시 컨테이너 생성
            const containerStyle = `
                width: 100%;
                max-width: 800px;
                margin: 0 auto;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
            `;
            
            // 각 컴포넌트 렌더링을 위한 임시 DOM 요소 생성
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.top = '-9999px';
            document.body.appendChild(tempDiv);
            
            // === 원래 PDF 생성 로직과 동일한 순서로 렌더링 ===
            // 1. Story 타입인 경우
            if (item.goods_type === 'story') {
                // 1.1. 표지 페이지 (CoverFairytale)
                ReactDOM.render(<CoverFairytale reportData={item} listData={[item]} />, tempDiv);
                await new Promise(resolve => setTimeout(resolve, 1000));
                
                // 새 창에 표시할 때 클래스명 유지하되 스타일을 오버라이드
                const coverSection = newWindow.document.createElement('div');
                coverSection.className = 'report-section';
                coverSection.setAttribute('style', containerStyle);
                coverSection.innerHTML = tempDiv.innerHTML;
                contentDiv.appendChild(coverSection);
                
                // 1.2. Report 이미지
                try {
                    const reportImageModule = await import(`../../assets/report/${item.sort_num}.png`);
                    const imgSection = newWindow.document.createElement('div');
                    imgSection.className = 'report-section';
                    imgSection.innerHTML = `<img src="${reportImageModule.default}" alt="Report" style="width:100%">`;
                    contentDiv.appendChild(imgSection);
                } catch (error) {
                    console.log('report 이미지를 찾을 수 없습니다:', error);
                }
                
                // 1.3. Report 컴포넌트
                try {
                    const ReportComponent = await loadReportComponent(item.sort_num);
                    if (ReportComponent) {
                        await renderComponentWithChartImages(
                            ReportComponent, 
                            { reportData: item, listData: [item] }, 
                            tempDiv
                        );
                        
                        const reportSection = newWindow.document.createElement('div');
                        reportSection.className = 'report-section';
                        reportSection.innerHTML = tempDiv.innerHTML;
                        contentDiv.appendChild(reportSection);
                    }
                } catch (error) {
                    console.error('Report 컴포넌트 렌더링 오류:', error);
                }
                
                // 1.4. ReportDetail 컴포넌트
                try {
                    const ReportDetailComponent = await loadReportDetailComponent(item.sort_num);
                    if (ReportDetailComponent) {
                        await renderComponentWithChartImages(
                            ReportDetailComponent,
                            { reportData: item },
                            tempDiv
                        );
                        
                        const detailSection = newWindow.document.createElement('div');
                        detailSection.className = 'report-section';
                        detailSection.innerHTML = tempDiv.innerHTML;
                        contentDiv.appendChild(detailSection);
                    }
                } catch (error) {
                    console.log('결과 상세 페이지를 찾을 수 없습니다:', error);
                }
                
                // 1.5. ReportDetail2 컴포넌트
                try {
                    const ReportDetail2Module = await import(`../../pages/Report/ReportDetail2/${item.sort_num}`);
                    const ReportDetail2Component = ReportDetail2Module.default;
                    
                    if (ReportDetail2Component && (typeof ReportDetail2Component === 'function' || typeof ReportDetail2Component === 'object')) {
                        await renderComponentWithChartImages(
                            ReportDetail2Component,
                            { reportData: item },
                            tempDiv
                        );
                        
                        const detail2Section = newWindow.document.createElement('div');
                        detail2Section.className = 'report-section';
                        detail2Section.innerHTML = tempDiv.innerHTML;
                        contentDiv.appendChild(detail2Section);
                    }
                } catch (error) {
                    console.log('결과 상세2 페이지를 찾을 수 없습니다:', error);
                }
                
                // 1.6. report2 이미지
                try {
                    const report2Path = await import(`../../assets/report2/${item.sort_num}.png`);
                    const img2Section = newWindow.document.createElement('div');
                    img2Section.className = 'report-section';
                    img2Section.innerHTML = `<img src="${report2Path.default}" alt="Report2" style="width:100%">`;
                    contentDiv.appendChild(img2Section);
                } catch (error) {
                    console.log('report2 이미지를 찾을 수 없습니다:', error);
                }
                
                // 1.7. report3 이미지
                try {
                    const report3Path = await import(`../../assets/report3/${item.sort_num}.png`);
                    const img3Section = newWindow.document.createElement('div');
                    img3Section.className = 'report-section';
                    img3Section.innerHTML = `<img src="${report3Path.default}" alt="Report3" style="width:100%">`;
                    contentDiv.appendChild(img3Section);
                } catch (error) {
                    console.log('report3 이미지를 찾을 수 없습니다:', error);
                }
            } 
            // 2. 일반/종합검사 타입인 경우
            else {
                // 2.1. 표지 페이지 (CoverReport)
                ReactDOM.render(<CoverReport reportData={item} listData={[item]} />, tempDiv);
                await new Promise(resolve => setTimeout(resolve, 1000));
                
                const coverSection = newWindow.document.createElement('div');
                coverSection.className = 'report-section';
                coverSection.innerHTML = tempDiv.innerHTML;
                contentDiv.appendChild(coverSection);
                
                // 2.2. 일반검사 타입일 경우 일반검사표지 추가
                if (item.goods_type === 'general') {
                    const generalCoverSection = newWindow.document.createElement('div');
                    generalCoverSection.className = 'report-section';
                    generalCoverSection.innerHTML = `<img src="${generalCoverImage}" alt="일반검사표지" style="width:100%">`;
                    contentDiv.appendChild(generalCoverSection);
                }
            }
            
            // 임시 요소 정리
            document.body.removeChild(tempDiv);
            
            // 로딩 메시지 제거
            const loadingElement = newWindow.document.querySelector('.loading');
            if (loadingElement && loadingElement.parentNode) {
                loadingElement.parentNode.removeChild(loadingElement);
            }
            
            // 스타일 문제를 해결하기 위한 추가 작업
            // 1. 모든 <img> 태그에 style="max-width:100%;" 추가
            Array.from(newWindow.document.querySelectorAll('img')).forEach(img => {
                img.style.maxWidth = '100%';
                img.style.height = 'auto';
            });
            
            // 2. 컨테이너와 섹션에 기본 스타일 적용
            Array.from(newWindow.document.querySelectorAll('.report-section')).forEach(section => {
                section.style.margin = '20px 0';
                section.style.padding = '15px';
            });
            
            // 차트 초기화 스크립트를 대체 (이미지로 변환했으므로 불필요)
            const fallbackChartScript = newWindow.document.createElement('script');
            fallbackChartScript.textContent = `
                // 사파리에서 차트를 대체하기 위한 이미지 확인
                console.log('사파리 브라우저 감지 - 차트 이미지 사용');

                // 이미지가 모두 로드되면 로딩 메시지 제거
                window.addEventListener('load', function() {
                    console.log('페이지 로드 완료');
                    
                    // 이미지가 잘 보이는지 확인
                    const chartImages = document.querySelectorAll('.report-graph img, .chart-container img');
                    console.log('차트 이미지 수:', chartImages.length);
                    
                    // 이미지 크기 조정
                    chartImages.forEach(img => {
                        img.style.width = '100%';
                        img.style.maxWidth = '100%';
                        img.style.display = 'block';
                        img.style.margin = '0 auto';
                    });
                });
            `;
            newWindow.document.body.appendChild(fallbackChartScript);
            
            // 사파리 차트 이미지 변환 스크립트
            const safariChartFallbackScript = newWindow.document.createElement('script');
            safariChartFallbackScript.textContent = `
                // 로드 완료 후 모든 캔버스 확인 및 변환
                window.addEventListener('load', function() {
                    console.log('사파리 차트 이미지 변환 실행');
                    
                    // 모든 캔버스 요소 찾기
                    const allCanvases = document.querySelectorAll('canvas');
                    console.log('찾은 캔버스 요소:', allCanvases.length);
                    
                    // 모든 차트 관련 컨테이너 찾기
                    const chartContainers = document.querySelectorAll('.report-graph, .chart-container');
                    console.log('찾은 차트 컨테이너:', chartContainers.length);
                    
                    // 차트 컨테이너 스타일 보정
                    chartContainers.forEach(container => {
                        container.style.width = '100%';
                        container.style.maxWidth = '100%';
                        container.style.display = 'block';
                        container.style.margin = '0 auto';
                        container.style.position = 'relative';
                        
                        // 이미지 찾기
                        const chartImage = container.querySelector('img');
                        if (chartImage) {
                            chartImage.style.width = '100%';
                            chartImage.style.maxWidth = '100%';
                            chartImage.style.display = 'block';
                            chartImage.style.margin = '0 auto';
                        }
                        // 차트 이미지가 없고 Canvas가 있으면 Canvas 스타일 조정
                        else if (container.querySelector('canvas')) {
                            const canvas = container.querySelector('canvas');
                            canvas.style.width = '100%';
                            canvas.style.maxWidth = '100%';
                            canvas.style.display = 'block';
                            canvas.style.margin = '0 auto';
                        }
                    });
                    
                    // 차트 범례 스타일 개선
                    const legendItems = document.querySelectorAll('.report-legend, .legend-item');
                    legendItems.forEach(item => {
                        item.style.display = 'flex';
                        item.style.flexWrap = 'wrap';
                        item.style.justifyContent = 'center';
                        item.style.alignItems = 'center';
                        item.style.margin = '10px 0';
                    });
                });
            `;
            newWindow.document.body.appendChild(safariChartFallbackScript);
            
            // 창 로드 완료
            newWindow.document.close();
            
        } catch (error) {
            console.error('새 창에 보고서 렌더링 오류:', error);
            
            // 오류 메시지 표시
            contentDiv.innerHTML = 
            `<div style="color:red;padding:20px;">
                <h3>보고서 생성 중 오류가 발생했습니다</h3>
                <p>${error.message}</p>
                <p>다시 시도하거나 Chrome 브라우저에서 실행해주세요.</p>
            </div>`;
            
            newWindow.document.close();
        }
    } catch (error) {
        console.error('보고서 열기 오류:', error);
        alert('보고서를 여는 중 오류가 발생했습니다.');
    }
};

function MyReportList({ listData }) {
    // const [loading, setLoading] = useState(false);
    // const [reportHash, setReportHash] = useState(null); // 모든 컴포넌트가 사용할 수 있는 hash 값 저장
    // const [reportData, setReportData] = useState(null); // GET 요청으로 받은 데이터 저장
    
    // 모든 동적 컴포넌트 로드 함수 수정
    const loadReportComponent = async (sortNum) => {
        try {
            const ReportModule = await import(`../../pages/Report/Report/${sortNum}`);
            return ReportModule.default;
        } catch (error) {
            console.error(`Report ${sortNum} 로드 실패:`, error);
            return null;
        }
    };
    
    // ReportDetail 컴포넌트 로드 함수도 수정
    const loadReportDetailComponent = async (sortNum) => {
        try {
            const ReportDetailModule = await import(`../Report/ReportDetail/${sortNum}`);
            return ReportDetailModule.default;
        } catch (error) {
            console.error(`ReportDetail ${sortNum} 로드 실패:`, error);
            return null;
        }
    };
    
    // handleDownloadPDF 함수 수정
    const handleDownloadPDF = async (item) => {
        console.log('PDF 다운로드 시작', isIOS ? '(iOS)' : '', isAndroid ? '(Android)' : '', isMobileApp ? '(App)' : '');
        
        try {
            // 1. POST 요청으로 hash 값만 가져오기
            const postResponse = await instance.post(INSPECTION_REPORT_URL, {
                report_seq: item.report_seq
            });
            
            if (!postResponse.data || !postResponse.data.data || !postResponse.data.data.hash) {
                throw new Error('유효한 hash 값을 가져오지 못했습니다');
            }
            
            const hashValue = postResponse.data.data.hash;
            
            // 2. hash 값으로 GET 요청 수행하여 모든 데이터 가져오기
            const getData = await fetchDataWithHash(hashValue);
            
            if (!getData) {
                throw new Error('GET 요청으로 데이터를 가져오지 못했습니다');
            }
            
            const inspectionTitle = getData.inspection_info.inspection_title;
            console.log('검사 제목:', inspectionTitle);
            
            // 3. 원본 item과 GET 데이터를 병합
            const modifiedItem = {
                ...item,
                report_seq: item.report_seq,
                // GET 응답으로 받은 모든 데이터 병합
                ...getData,
                // hash 값도 함께 저장 (필요한 경우 컴포넌트에서 사용할 수 있도록)
                _hash: hashValue
            };
            
            // 모바일 앱이거나 iOS/Safari에서는 새 창에서 콘텐츠를 렌더링
            if (isMobileApp || isIOS || isSafari) {
                await openReportInNewWindow(modifiedItem, inspectionTitle);
                return;
            }
            
            // 일반/종합검사 리포트 처리
            if (item.goods_type !== 'story') {
                const pdf = new jsPDF('p', 'mm', 'a4');

                try {
                    const tempDiv = document.createElement('div');
                    tempDiv.style.position = 'absolute';
                    tempDiv.style.left = '-9999px';
                    tempDiv.style.top = '-9999px';
                    document.body.appendChild(tempDiv);

                    // 표지 페이지 렌더링
                    ReactDOM.render(<CoverReport reportData={modifiedItem} listData={listData} />, tempDiv);

                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // 표지 페이지 캡처
                    const coverCanvas = await html2canvas(tempDiv, {
                        scale: 2,
                        useCORS: true,
                        logging: false,
                        backgroundColor: null,
                    });

                    const coverImgData = coverCanvas.toDataURL('image/png');
                    const coverImgWidth = 210;
                    const coverImgHeight = (coverCanvas.height * coverImgWidth) / coverCanvas.width;

                    // 표지 페이지 추가
                    pdf.addImage(coverImgData, 'PNG', 0, 0, coverImgWidth, coverImgHeight);

                    // 일반검사 타입일 경우 두 번째 페이지에 일반검사표지 추가
                    if (item.goods_type === 'general') {
                        pdf.addPage();
                        const img = new Image();
                        img.src = generalCoverImage;

                        await new Promise((resolve, reject) => {
                            img.onload = resolve;
                            img.onerror = reject;
                        });

                        const imgCanvas = document.createElement('canvas');
                        const imgCtx = imgCanvas.getContext('2d');
                        imgCanvas.width = img.width;
                        imgCanvas.height = img.height;
                        imgCtx.drawImage(img, 0, 0);

                        const imgData = imgCanvas.toDataURL('image/png');
                        pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 크기로 설정
                    }

                    pdf.save(`${inspectionTitle}_report.pdf`);
                    document.body.removeChild(tempDiv);
                } catch (error) {
                    console.error('PDF 생성 중 오류:', error);
                    alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
                }
                return;
            }

            // 동화 감성검사 리포트 처리
            const pdf = new jsPDF('p', 'mm', 'a4');

            try {
                // Report 컴포넌트 미리 렌더링
                const tempDiv = document.createElement('div');
                tempDiv.style.position = 'absolute';
                tempDiv.style.left = '-9999px';
                tempDiv.style.top = '-9999px';
                document.body.appendChild(tempDiv);

                // Report 컴포넌트 로드 시 실제 sort_num 사용
                const ReportComponent = await loadReportComponent(item.sort_num);
                if (!ReportComponent) {
                    throw new Error(`Report 컴포넌트를 찾을 수 없습니다: ${item.sort_num}`);
                }

                ReactDOM.render(
                    <ReportComponent reportData={modifiedItem} listData={listData} />,
                    tempDiv
                );

                await new Promise((resolve) => setTimeout(resolve, 1000));

                const reportCanvas = await html2canvas(tempDiv, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                    backgroundColor: null,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: tempDiv.scrollHeight,
                    onclone: (clonedDoc) => {
                        const splitHeight = Math.floor(tempDiv.scrollHeight * 0.7);
                        const elements = clonedDoc.getElementsByTagName('*');

                        Array.from(elements).forEach((element) => {
                            const rect = element.getBoundingClientRect();
                            const elementBottom = rect.bottom;
                            const elementTop = rect.top;

                            // 분할 지점 주변 (위아래 100px) 요소들만 처리
                            if (Math.abs(elementBottom - splitHeight) < 100 || Math.abs(elementTop - splitHeight) < 100) {
                                // 기존 스타일 저장
                                const originalBorder = element.style.border;
                                const originalBackground = element.style.backgroundImage;

                                // PDF 생성을 위한 임시 스타일 적용
                                element.style.border = 'none';
                                element.style.borderTop = 'none';
                                element.style.borderBottom = 'none';

                                // 분할 지점에서만 background-image 제거
                                if (Math.abs(elementBottom - splitHeight) < 50) {
                                    element.style.backgroundImage = 'none';
                                }

                                // 나머지 부분은 원래 스타일 유지
                                setTimeout(() => {
                                    element.style.border = originalBorder;
                                    element.style.backgroundImage = originalBackground;
                                }, 0);
                            }
                        });
                    },
                });

                const reportImgData = reportCanvas.toDataURL('image/png');
                const reportImgWidth = 210;
                const reportImgHeight = (reportCanvas.height * reportImgWidth) / reportCanvas.width;
                const xOffset = (210 - reportImgWidth) / 2;
                const pageHeight = 297; // A4 높이 (mm)

                // 1페이지: report1 이미지
                try {
                    // CoverFairytale 컴포넌트 렌더링
                    ReactDOM.render(<CoverFairytale reportData={modifiedItem} listData={listData} />, tempDiv);

                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // 표지 페이지 캡처
                    const coverCanvas = await html2canvas(tempDiv, {
                        scale: 2,
                        useCORS: true,
                        logging: false,
                        backgroundColor: null,
                    });

                    const coverImgData = coverCanvas.toDataURL('image/png');
                    const coverImgWidth = 210;
                    const coverImgHeight = (coverCanvas.height * coverImgWidth) / coverCanvas.width;

                    // PDF에 이미지 추가
                    pdf.addImage(coverImgData, 'PNG', 0, 0, coverImgWidth, coverImgHeight);
                } catch (error) {
                    console.log('report_1 이미지를 찾을 수 없습니다.');
                }

                // 2페이지: report 이미지
                pdf.addPage();
                try {
                    // 이미지 로드 시 실제 sort_num 사용
                    const imagePath = await import(`../../assets/report/${item.sort_num}.png`);
                    const img = new Image();
                    img.src = imagePath.default;

                    await new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });

                    const imgCanvas = document.createElement('canvas');
                    const imgCtx = imgCanvas.getContext('2d');
                    const a4WidthInPixels = 210 * 3.78;
                    imgCanvas.width = a4WidthInPixels;
                    imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                    imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                    const imgData = imgCanvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
                } catch (error) {
                    console.log('report 이미지를 찾을 수 없습니다.');
                }

                // 3페이지와 4페이지에 Report 컴포넌트 표시
                pdf.addPage();

                try {
                    const threshold = 20; // 20mm 정도의 여유 공간
                    const totalPages = Math.ceil((reportImgHeight - threshold) / pageHeight);
                    
                    // 각 페이지별로 내용 분할
                    for (let i = 0; i < totalPages; i++) {
                        if (i > 0) pdf.addPage();
                        
                        const yStart = i * pageHeight;
                        
                        pdf.addImage(
                            reportImgData,
                            'PNG',
                            xOffset,
                            -yStart, // 음수값으로 설정하여 이미지 위치 조정
                            reportImgWidth,
                            reportImgHeight
                        );
                    }
                } catch (error) {
                    console.error('Report 페이지 생성 중 오류:', error);
                }

                // 4페이지 (결과 상세)
                try {
                    const ReportDetailComponent = await loadReportDetailComponent(item.sort_num);
                    if (ReportDetailComponent) {
                        pdf.addPage();
                        ReactDOM.render(<ReportDetailComponent reportData={modifiedItem} />, tempDiv);

                        await new Promise((resolve) => setTimeout(resolve, 1000));

                        // 결과 상세 페이지 캡처
                        const detailCanvas = await html2canvas(tempDiv, {
                            scale: 2,
                            useCORS: true,
                            logging: false,
                            backgroundColor: null,
                            windowWidth: document.body.scrollWidth,
                            windowHeight: tempDiv.scrollHeight,
                        });

                        const detailImgData = detailCanvas.toDataURL('image/png');
                        const detailImgWidth = 210;
                        const detailImgHeight = (detailCanvas.height * detailImgWidth) / detailCanvas.width;

                        // 한 페이지 높이 (297mm)를 기준으로 필요한 페이지 수 계산
                        const pageHeight = 297;
                        const totalPages = Math.ceil(detailImgHeight / pageHeight);

                        // 각 페이지별로 내용 분할
                        for (let i = 0; i < totalPages; i++) {
                            if (i > 0) pdf.addPage();

                            const yStart = i * pageHeight;
                            pdf.addImage(
                                detailImgData,
                                'PNG',
                                0,
                                -yStart,
                                detailImgWidth,
                                detailImgHeight
                            );
                        }
                    }
                } catch (error) {
                    console.log('결과 상세 페이지를 찾을 수 없습니다.');
                }
                
                // 4-1페이지 (결과 상세2)
                try {
                    const ReportDetail2Module = await import(`../../pages/Report/ReportDetail2/${item.sort_num}`);
                    const ReportDetail2Component = ReportDetail2Module.default;  // default export를 명시적으로 가져옴
                    
                    if (ReportDetail2Component) {
                        pdf.addPage();
                        // 컴포넌트가 유효한지 확인
                        if (typeof ReportDetail2Component === 'function' || typeof ReportDetail2Component === 'object') {
                            ReactDOM.render(
                                React.createElement(ReportDetail2Component, { reportData: modifiedItem }),
                                tempDiv
                            );

                            await new Promise((resolve) => setTimeout(resolve, 1000));

                            // 결과 상세 페이지 캡처
                            const detail2Canvas = await html2canvas(tempDiv, {
                                scale: 2,
                                useCORS: true,
                                logging: false,
                                backgroundColor: null,
                                windowWidth: document.body.scrollWidth,
                                windowHeight: tempDiv.scrollHeight,
                            });

                            const detail2ImgData = detail2Canvas.toDataURL('image/png');
                            const detail2ImgWidth = 210;
                            const detail2ImgHeight = (detail2Canvas.height * detail2ImgWidth) / detail2Canvas.width;

                            // 한 페이지 높이 (297mm)를 기준으로 필요한 페이지 수 계산
                            const pageHeight = 297;
                            const totalPages = Math.ceil(detail2ImgHeight / pageHeight);

                            // 각 페이지별로 내용 분할
                            for (let i = 0; i < totalPages; i++) {
                                if (i > 0) pdf.addPage();

                                const yStart = i * pageHeight;
                                pdf.addImage(
                                    detail2ImgData,
                                    'PNG',
                                    0,
                                    -yStart,
                                    detail2ImgWidth,
                                    detail2ImgHeight
                                );
                            }
                        } else {
                            console.log('유효하지 않은 컴포넌트입니다:', typeof ReportDetail2Component);
                        }
                    }
                } catch (error) {
                    console.log('결과 상세2 페이지를 찾을 수 없습니다:', error);
                }

                // 5페이지: report2 이미지
                pdf.addPage();
                try {
                    // report2 이미지도 실제 sort_num 사용
                    const report2Path = await import(`../../assets/report2/${item.sort_num}.png`);
                    const img = new Image();
                    img.src = report2Path.default;

                    await new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });

                    const imgCanvas = document.createElement('canvas');
                    const imgCtx = imgCanvas.getContext('2d');
                    const a4WidthInPixels = 210 * 3.78;
                    imgCanvas.width = a4WidthInPixels;
                    imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                    imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                    const imgData = imgCanvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
                } catch (error) {
                    console.log('report2 이미지를 찾을 수 없습니다.');
                }

                // 6페이지: report3 이미지
                pdf.addPage();
                try {
                    // report3 이미지도 실제 sort_num 사용
                    const report3Path = await import(`../../assets/report3/${item.sort_num}.png`);
                    const img = new Image();
                    img.src = report3Path.default;

                    await new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });

                    const imgCanvas = document.createElement('canvas');
                    const imgCtx = imgCanvas.getContext('2d');
                    const a4WidthInPixels = 210 * 3.78;
                    imgCanvas.width = a4WidthInPixels;
                    imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                    imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                    const imgData = imgCanvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
                } catch (error) {
                    console.log('report3 이미지를 찾을 수 없습니다.');
                }

                pdf.save(`${inspectionTitle}_report.pdf`);
                document.body.removeChild(tempDiv);
            } catch (error) {
                console.error('PDF 생성 중 오류:', error);
                alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            console.error('API 호출 오류:', error);
            alert('보고서 정보를 가져오는데 실패했습니다.');
        }
    };

    return (
        <div className="forward_type my_list">
            {listData.map((item, index) => (
                <div className="assessment_info childrenDetail" key={`${item.goods_seq}-${index}`}>
                    <div className="assessment_details">
                        <div className="assessment_title ellipsis1">{item.goods_name}</div>
                        <div className="assessment_meta group_line" style={{ paddingTop: '6px' }}>
                            {item?.story_title_sub && <div className="assessment_code group_item">{item.story_title_sub}</div>}
                            {item?.inspection_cnt && <div className="assessment_code group_item">{item.inspection_cnt}회차</div>}
                        </div>
                        <button type="button" className="resend_button" onClick={() => handleDownloadPDF(item)}>
                            PDF 다운받기
                        </button>
                    </div>
                    <div className="children_image_container">
                        <img className="Children_Img" src={item.list_image} alt="Children_Img" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MyReportList;
