import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {KAKAO_CLIENT_ID, NAVER_CLIENT_ID} from "../../utils/urls";
import axios from "axios";
import {decodeToken} from "../../utils/token";
import {toast} from "react-toastify";
import {loginAppleUser, loginKakaoUser, loginNaverUser} from "../../services/Join/Join";
import useUserStore from "../../store/user/userStore";

const {naver} = window;

export const useSnsUserLogin = () => {
    const {updateTokens, updateUserInfo} = useUserStore()
    const navigate = useNavigate();
    const loginSnsUser = useCallback(async (type, params) => {
        console.log(`[${type} 로그인] 시작:`, params);
        
        const loginData = {
            ...(type === 'naver' ? {naver_access_token: params.idToken} : {id_token: params.idToken}),
            push_id: params.push_id || '' // 푸시 토큰 파라미터 추가
        };
        
        try {
            let response;
            switch (type) {
                case 'naver':
                    response = await loginNaverUser(loginData);
                    break;
                case 'kakao':
                    response = await loginKakaoUser(loginData);
                    break;
                case 'apple':
                    console.log('[애플 로그인] API 요청:', loginData);
                    response = await loginAppleUser(loginData);
                    console.log('[애플 로그인] API 응답:', response);
                    break;
                default:
                    console.warn(`지원하지 않는 SNS 타입: ${type}`);
                    return null;
            }

            if (response.data.code === 200) {
                updateTokens(response.data.data.access_token);
                updateUserInfo(decodeToken(response.data.data.access_token))
                toast('로그인 되었습니다.')
                navigate('/')
                return response;
            } else if(response.data.code === 400){
                // 회원이 아닌경우
                toast(response.data.message)
                navigate('/join')
                return response;
            } else {
                console.log(`[${type} 로그인] 오류 응답:`, response.data);
                toast(response.data.message || `${type} 로그인에 실패했습니다`);
                return response;
            }
        } catch (error) {
            console.error(`[${type} 로그인] 오류:`, error);
            toast(`${type} 로그인 처리 중 오류가 발생했습니다`);
            throw error;
        }
    }, [updateTokens, navigate, updateUserInfo]);

    return {loginSnsUser};
}

export const useNaverLogin = (redirectUri) => {
    const location = useLocation();
    const [snsInfo, setSnsInfo] = useState(null);

    const naverLogin = useMemo(() => {
        return new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: redirectUri,
            loginButton: {color: "green", type: 3, height: "48"},
        });
    }, [redirectUri]);

    useEffect(() => {
        if (redirectUri && location.pathname === "/login") { // '/login' 페이지에서만 실행
            naverLogin.init();
        }
    }, [naverLogin, redirectUri, location.pathname]);
    const handleNaverLogin = () => {
        naverLogin.authorize();
    };
    useEffect(() => {
        const params = new URLSearchParams(location.hash.replace("#", "") || location.search);

        if (params.has("access_token")) {
            naverLogin.getLoginStatus((status) => {
                if (status) {
                    const snsUser = {
                        fromSns: true,
                        provider: 'naver',
                        snsUserInfo: {
                            id: '',
                            email: naverLogin.user.email,
                            name: naverLogin.user.name,
                            nickname: naverLogin.user.nickname,
                            id_token: naverLogin.accessToken.accessToken,
                        }
                    };
                    setSnsInfo(snsUser);
                }
            });
        }
    }, [location.hash,location.search,location.key, naverLogin]);

    return {handleNaverLogin, snsInfo};
};

export const useKakaoLogin = (redirectUri) => {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const code = queryParams.get('code');
    const [snsInfo, setSnsInfo] = useState(null);
    const handleKakaoLogin = () => {
        window.location.href =
            `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=openid`; // 카카오 로그인 페이지로 리디렉션
    };

    const handelKakaoLoginToken = useCallback(async () => {
        try {
            // 1. 카카오 API에서 액세스 토큰과 ID 토큰 요청
            const tokenResponse = await axios.post(
                'https://kauth.kakao.com/oauth/token',
                new URLSearchParams({
                    grant_type: 'authorization_code',
                    client_id: KAKAO_CLIENT_ID,
                    redirect_uri: redirectUri,
                    code: code,
                    scope: 'profile_nickname profile_image account_email'
                })
            );

            console.log('카카오 토큰 응답:', tokenResponse.data);
            const {access_token, id_token} = tokenResponse.data;

            // 2. 사용자 정보 요청
            const userInfoResponse = await axios.get('https://kapi.kakao.com/v2/user/me', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            const userInfo = userInfoResponse.data;


            const snsUser = {
                fromSns: true,
                provider: 'kakao',
                snsUserInfo: {
                    id: userInfo.kakao_account?.email,
                    email: userInfo.kakao_account?.email,
                    name: userInfo.properties?.name || "",
                    nickname: userInfo.properties?.nickname || "",
                    id_token: id_token
                }
            };
            setSnsInfo(snsUser);
        } catch (error) {
            console.error('카카오 로그인 처리 중 오류 발생:', error);

        }
    }, [code, redirectUri])

    useEffect(() => {
        if (code) {
            handelKakaoLoginToken()
        }
    }, [code, handelKakaoLoginToken]);

    return {handleKakaoLogin, snsInfo}
}


export const useAppleLogin = (redirectUri) => {
    const [snsInfo, setSnsInfo] = useState(null);
    
    console.log('[useAppleLogin] 초기화, redirectUri:', redirectUri);
    
    // 웹뷰 체크 함수
    const isWebView = useCallback(() => {
        // window.isWebview 플래그 확인 (앱에서 설정한 플래그)
        if (window.isWebview === true) {
            console.log('[useAppleLogin] window.isWebview 플래그로 웹뷰 확인됨');
            return true;
        }
        
        // 추가적인 웹뷰 감지 로직 (백업)
        const ua = navigator.userAgent || '';
        const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(ua);
        const isAndroidWebView = /wv|WebView/.test(ua);
        
        return isIOSWebView || isAndroidWebView;
    }, []);
    
    // 웹 환경에서만 애플 로그인 SDK 초기화
    useEffect(() => {
        if (!isWebView() && window.AppleID) {
            try {
                // 안전한 UUID 생성
                const generateId = () => {
                    if (crypto.randomUUID) {
                        return crypto.randomUUID();
                    }
                    return Math.random().toString(36).substring(2, 15);
                };
                
                window.AppleID.auth.init({
                    clientId: 'com.iqvitamininside.service',
                    scope: 'name email',
                    redirectURI: redirectUri,
                    state: generateId(),
                    nonce: generateId(),
                    usePopup: true,
                    responseType: 'code id_token',
                });
                console.log('[useAppleLogin] 웹 환경에서 애플 로그인 초기화 성공');
            } catch (error) {
                console.error('[useAppleLogin] 애플 로그인 초기화 오류:', error);
            }
        }
    }, [redirectUri, isWebView]);
    
    // 앱으로부터 메시지 수신 처리 (앱 환경 전용)
    useEffect(() => {
        if (!isWebView()) {
            return;
        }
        
        const handleAppMessage = (event) => {
            console.log('[useAppleLogin] 앱 메시지 수신:', event.type);
            
            let data;
            try {
                // 문자열인 경우 파싱
                if (typeof event.data === 'string') {
                    try {
                        data = JSON.parse(event.data);
                        console.log('[useAppleLogin] 파싱된 데이터:', data);
                    } catch (e) {
                        console.warn('[useAppleLogin] 파싱 실패, 원본 사용');
                        data = event.data;
                    }
                } else {
                    data = event.data;
                }
                
                console.log('[useAppleLogin] 수신된 앱 데이터:', data);
                
                // 애플 로그인 응답 처리
                if (data && data.key === "APPLE_LOGIN") {
                    console.log('[useAppleLogin] 앱에서 애플 로그인 응답 감지');
                    
                    // 앱에서 전달된 value 객체 사용
                    const { id_token, user, email, fullName } = data.value || {};
                    
                    if (id_token) {
                        console.log('[useAppleLogin] 앱에서 유효한 identityToken 수신:', id_token.substring(0, 20) + '...');
                        
                        // 사용자 정보 구성
                        const snsUser = {
                            fromSns: true,
                            provider: 'apple',
                            snsUserInfo: {
                                id: user || "",
                                email: email || "",
                                name: fullName?.givenName || fullName?.nickname || "",
                                nickname: "",
                                id_token: id_token
                            }
                        };
                        
                        console.log('[useAppleLogin] 생성된 SNS 사용자 정보:', snsUser);
                        setSnsInfo(snsUser);
                    } else {
                        console.error('[useAppleLogin] 앱에서 identityToken 누락됨:', data.value);
                    }
                }
                // 백업으로 기존 APPLE_LOGIN_RESPONSE 형식도 처리 (필요시)
                else if (data && (data.key === "APPLE_LOGIN_RESPONSE" || data.type === "APPLE_LOGIN_RESPONSE")) {
                    console.log('[useAppleLogin] 기존 형식의 응답 감지');
                    
                    // 앱에서 전달된 데이터 추출
                    const payload = data.value || data.data || data;
                    const { id_token, email, name, sub } = payload || {};
                    
                    if (id_token) {
                        console.log('[useAppleLogin] 기존 형식에서 토큰 발견');
                        
                        const snsUser = {
                            fromSns: true,
                            provider: 'apple',
                            snsUserInfo: {
                                id: sub || "",
                                email: email || "",
                                name: name || "",
                                nickname: "",
                                id_token: id_token
                            }
                        };
                        
                        setSnsInfo(snsUser);
                    }
                }
            } catch (error) {
                console.error('[useAppleLogin] 앱 메시지 처리 오류:', error);
            }
        };
        
        // 앱 이벤트 리스너 등록
        window.addEventListener('message', handleAppMessage);
        document.addEventListener('message', handleAppMessage); // iOS WebView 지원
        
        return () => {
            window.removeEventListener('message', handleAppMessage);
            document.removeEventListener('message', handleAppMessage);
        };
    }, [isWebView]);
    
    // 웹 환경에서의 애플 로그인 SDK 초기화 (웹 전용)
    useEffect(() => {
        if (isWebView()) {
            return; // 앱 환경에서는 웹 SDK 초기화 필요 없음
        }
        
        console.log('[useAppleLogin] 웹 환경에서 SDK 확인');
        
        if (!window.AppleID) {
            console.error('[useAppleLogin] AppleID SDK가 로드되지 않음');
            return;
        }
        
        // signIn() 호출을 제거하고 초기화 상태만 확인
        console.log('[useAppleLogin] 웹 환경에서 AppleID SDK 사용 준비 완료');
        
    }, [isWebView]);
    
    // 애플 로그인 처리 함수
    const handleAppleLogin = useCallback(() => {
        console.log('[useAppleLogin] 애플 로그인 시작');
        
        // 웹뷰 환경 확인 및 요청 전송
        if (isWebView()) {
            console.log('[useAppleLogin] 앱 환경 감지, 앱에 로그인 요청 전송');
            
            // 모든 앱(iOS/안드로이드) 공통으로 사용할 기본 메시지 전송 방식
            // window.postMessage를 기본 통신 방식으로 사용
            window.postMessage({key: "APPLE_LOGIN"}, "*");
            console.log('[useAppleLogin] window.postMessage({key: "APPLE_LOGIN"}) 전송 완료');
            
            // 백업 메서드 (기존 방식 유지 - 위 방식이 동작하지 않을 경우를 대비)
            // iOS 전용 백업 메서드
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.appleLogin) {
                window.webkit.messageHandlers.appleLogin.postMessage({key: "APPLE_LOGIN"});
                console.log('[useAppleLogin] iOS webkit 메시지핸들러 백업 메서드 호출됨');
            }
            
            // 안드로이드 전용 백업 메서드
            if (window.Android && window.Android.requestAppleLogin) {
                window.Android.requestAppleLogin();
                console.log('[useAppleLogin] 안드로이드 백업 메서드 호출됨');
            }
            
            return;
        }
        
        // 웹 환경에서 애플 로그인 SDK 사용
        console.log('[useAppleLogin] 웹 환경에서 로그인 실행');
        
        if (!window.AppleID) {
            console.error('[useAppleLogin] AppleID SDK가 로드되지 않음');
            toast('애플 로그인을 초기화할 수 없습니다');
            return;
        }
        
        window.AppleID.auth.signIn()
            .then(data => {
                console.log('[useAppleLogin] 애플 로그인 성공:', data);
                
                if (data?.authorization?.id_token) {
                    const idToken = data.authorization.id_token;
                    
                    try {
                        // JWT 토큰 디코딩
                        const tokenParts = idToken.split('.');
                        if (tokenParts.length === 3) {
                            const payload = JSON.parse(atob(tokenParts[1]));
                            console.log('[useAppleLogin] 토큰 디코딩 성공:', payload);
                            
                            const snsUser = {
                                fromSns: true,
                                provider: 'apple',
                                snsUserInfo: {
                                    id: payload.sub || "",
                                    email: payload.email || "",
                                    name: payload.name || "",
                                    nickname: "",
                                    id_token: idToken
                                }
                            };
                            
                            setSnsInfo(snsUser);
                        } else {
                            console.error('[useAppleLogin] 유효하지 않은 토큰 형식');
                        }
                    } catch (error) {
                        console.error('[useAppleLogin] 토큰 디코딩 오류:', error);
                    }
                } else {
                    console.error('[useAppleLogin] id_token 누락됨');
                }
            })
            .catch(error => {
                console.error('[useAppleLogin] 애플 로그인 오류:', error);
                
                // 사용자가 팝업을 닫은 경우는 무시
                if (error?.error !== "popup_closed_by_user") {
                    toast('애플 로그인 처리 중 오류가 발생했습니다');
                }
            });
    }, [isWebView]);
    
    return { snsInfo, handleAppleLogin };
}