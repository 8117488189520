import React, {useEffect, useState, useCallback} from "react";
import {Link, useNavigate} from "react-router-dom";
import {loginUser} from "../../services/Auth/auth";
import useUserStore from "../../store/user/userStore";
import {useAutoLogin} from "../../hooks/login/useAutoLogin";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import loginRectangle from "../../assets/img/loginRectangle.png";
import NaverLogin from "../../assets/img/ic_sns_naver.png";
import KakaoLogin from "../../assets/img/ic_sns_KakaoTalk.png";
import AppleLogin from "../../assets/img/ic_sns_AppleTalk.png";
import MainLogo from "../../assets/img/logo.png";
import {useAppleLogin, useKakaoLogin, useNaverLogin, useSnsUserLogin} from "../../hooks/login/snsLogin";
import {KAKAO_LOGIN_REDIRECT_URI, NAVER_LOGIN_REDIRECT_URI} from "../../utils/urls";
import { passwordvisible } from "../../utils/passwordvisible";
import { setCookie } from "../../utils/cookies";
import { decodeToken, getRemainingTime } from "../../utils/token";
import { usePushToken } from "../../store/user/usePushToken";

function Login() {
    const naverLoginInfo = useNaverLogin(NAVER_LOGIN_REDIRECT_URI); // 네이버 로그인 유저정보
    const kakaoLoginInfo = useKakaoLogin(KAKAO_LOGIN_REDIRECT_URI); // 카카오 로그인 유저정보
    const currentOrigin = window.location.origin;
    const appleLoginInfo = useAppleLogin(`${currentOrigin}/login`);
    const {loginSnsUser} = useSnsUserLogin()
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isShowPw, setShowPw] = useState(false);
    const [loginForm, setLoginForm] = useState({
        id: '',
        password: '',
        autoLogin: false
    });
    const { pushToken, requestPushToken } = usePushToken();

    // 자동 로그인 훅 사용
    useAutoLogin();

    // 컴포넌트 마운트 시 저장된 로그인 정보 불러오기
    useEffect(() => {
        const autoLoginEnabled = localStorage.getItem('autoLogin') === 'true';
        const savedId = localStorage.getItem('savedId');
        const savedPassword = localStorage.getItem('savedPassword');

        if (autoLoginEnabled && savedId && savedPassword) {
            setLoginForm(prev => ({
                ...prev,
                id: savedId,
                password: atob(savedPassword),
                autoLogin: true
            }));
        }
    }, []);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setLoginForm(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    // SNS 로그인 처리 함수를 먼저 선언하고 useCallback으로 감싸기
    const handleSnsLoginSuccess = useCallback(async (type, token, pushId = '') => {
        try {
            const response = await loginSnsUser(type, { 
                idToken: token,
                push_id: pushId  // 푸시 토큰 파라미터 추가
            });
            
            // 디버깅을 위한 로그 추가
            console.log('SNS 로그인 응답:', response);
            
            if (response?.data?.code === 200) {
                const {access_token} = response.data.data;
                
                // 1. 토큰 유효성 검증
                const decodedToken = decodeToken(access_token);
                if (!decodedToken) {
                    setError('유효하지 않은 토큰입니다.');
                    return;
                }

                // 2. 토큰 만료 시간 확인
                const remainingTime = getRemainingTime(decodedToken);
                if (remainingTime <= 0) {
                    setError('만료된 토큰입니다.');
                    return;
                }

                // 3. 토큰 저장 (여러 저장소에 동시에 저장)
                localStorage.setItem('accessToken', access_token);
                sessionStorage.setItem('accessToken', access_token);
                setCookie('access_token', access_token, 30);
                
                // 4. SNS 로그인 타입 저장
                localStorage.setItem('loginType', type);
                
                // 5. 사용자 정보 저장
                const userInfo = {
                    ...decodedToken,
                    user_name: decodedToken.username,
                    email: response.data.data.email
                };
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                
                // 6. Zustand 스토어 업데이트
                useUserStore.getState().updateTokens(access_token);
                useUserStore.getState().updateUserInfo(userInfo);

                navigate('/');
            } else if (response?.data) {
                // response 객체가 존재하면 직접 처리 (response가 반환되지 않는 경우 대비)
                console.log('SNS 로그인 응답 데이터:', response.data);
                
                // 사용자 정보가 있는 경우 (응답 구조 확인 필요)
                if (response.data.data && response.data.data.member_seq) {
                    console.log('사용자 정보가 있어 수동으로 처리합니다');
                    
                    // 토큰이 없는 경우 대비하여 데이터에서 토큰 확인
                    const access_token = response.data.data.access_token || token;
                    
                    // 사용자 정보 추출
                    const userInfo = {
                        user_name: response.data.data.user_name,
                        member_seq: response.data.data.member_seq,
                        email: response.data.data.email,
                        nickname: response.data.data.nickname,
                        // 필요한 다른 필드 추가
                    };
                    
                    // 토큰 및 사용자 정보 저장
                    localStorage.setItem('accessToken', access_token);
                    sessionStorage.setItem('accessToken', access_token);
                    setCookie('access_token', access_token, 30);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    localStorage.setItem('loginType', type);
                    
                    // Zustand 스토어 업데이트
                    useUserStore.getState().updateTokens(access_token);
                    useUserStore.getState().updateUserInfo(userInfo);
                    
                    navigate('/');
                    return;
                }
                
                // 오류 메시지가 있는 경우
                throw new Error(response.data.message || 'SNS 로그인에 실패했습니다.');
            } else {
                throw new Error('SNS 로그인에 실패했습니다.');
            }
        } catch (error) {
            console.error('SNS 로그인 실패:', error);
            console.error('오류 상세:', error.response || error.message);
            setError(error.response?.data?.message || 'SNS 로그인에 실패했습니다.');
        }
    }, [navigate, setError, loginSnsUser]);

    // SNS 로그인 효과들
    useEffect(() => {
        if (naverLoginInfo?.snsInfo?.snsUserInfo) {
            // pushToken 가져와서 전달
            handleSnsLoginSuccess("naver", naverLoginInfo.snsInfo.snsUserInfo.id_token, pushToken);
        }
    }, [naverLoginInfo.snsInfo, handleSnsLoginSuccess, pushToken]);

    useEffect(() => {
        if (kakaoLoginInfo?.snsInfo?.snsUserInfo) {
            // pushToken 가져와서 전달
            handleSnsLoginSuccess("kakao", kakaoLoginInfo.snsInfo.snsUserInfo.id_token, pushToken);
        }
    }, [kakaoLoginInfo.snsInfo, handleSnsLoginSuccess, pushToken]);

    useEffect(() => {
        if (appleLoginInfo?.snsInfo?.snsUserInfo) {
            console.log('로그인 페이지에서 애플 로그인 정보 감지:', appleLoginInfo.snsInfo);
            const token = appleLoginInfo.snsInfo.snsUserInfo.id_token;
            
            // 토큰 상세 정보 로깅
            if (token) {
                console.log('애플 로그인 토큰 길이:', token.length);
                console.log('토큰 일부:', token.substring(0, 20) + '...');
                
                try {
                    // JWT 형식 확인 (점으로 구분된 3개의 부분)
                    const parts = token.split('.');
                    console.log('토큰 파트 수:', parts.length);
                    
                    if (parts.length === 3) {
                        // 유효한 JWT 형식
                        console.log('애플 로그인 시도');
                        // pushToken 가져와서 전달
                        handleSnsLoginSuccess("apple", token, pushToken);
                    } else {
                        console.error('유효하지 않은 JWT 토큰 형식');
                        setError('인증 토큰 형식이 올바르지 않습니다');
                    }
                } catch (e) {
                    console.error('토큰 검증 중 오류:', e);
                    setError('인증 토큰 처리 중 오류가 발생했습니다');
                }
            } else {
                console.error('유효한 애플 토큰이 없습니다.');
                setError('애플 로그인 토큰이 유효하지 않습니다.');
            }
        }
    }, [appleLoginInfo.snsInfo, handleSnsLoginSuccess, setError, pushToken]);

    const handleLogin = async () => {
        try {
            // 디바이스 타입 확인 (iOS인지 안드로이드인지)
            const isIOS = (() => {
                const userAgent = navigator.userAgent.toLowerCase();
                return /iphone|ipad|ipod/i.test(userAgent);
            })();
            
            const isAndroid = (() => {
                const userAgent = navigator.userAgent.toLowerCase();
                return /android/i.test(userAgent);
            })();
            
            const {id, password, autoLogin} = loginForm;
            let response;
            
            // iOS용 특별 처리
            if (isIOS) {
                console.log("[Login] iOS 환경 감지, 특별 처리 적용");
                
                // 1. 먼저 요청 보내기
                window.postMessage({key: "DEVICE_TOKEN"}, "*");
                console.log("[Login] iOS - 푸시 토큰 요청 전송");
                
                // 2. iOS는 충분한 대기 시간 필요
                await new Promise(resolve => setTimeout(resolve, 3000));
                
                // 3. 토큰 확인
                let devicePushToken = window._savedPushToken || pushToken || '';
                console.log("[Login] iOS - 토큰 확인 결과:", devicePushToken);
                
                // 4. 토큰이 없으면 하드코딩된 값 사용 (iOS 웹뷰 이슈 대응)
                if (!devicePushToken) {
                    // 개발팀과 협의된 임시 값 또는 널 방지 값
                    devicePushToken = "ios_temp_token"; 
                    console.log("[Login] iOS - 임시 토큰 사용:", devicePushToken);
                }
                
                // 5. 로그인 요청
                console.log("[Login] iOS - 로그인 요청, push_id:", devicePushToken);
                response = await loginUser(id, password, autoLogin, devicePushToken);
            }
            // 안드로이드용 처리
            else if (isAndroid) {
                console.log("[Login] 안드로이드 환경 감지");
                
                // 안드로이드는 기존 로직대로 처리 (빠른 응답)
                let devicePushToken = window._savedPushToken || pushToken || '';
                
                if (!devicePushToken) {
                    window.postMessage({key: "DEVICE_TOKEN"}, "*");
                    await new Promise(resolve => setTimeout(resolve, 500));
                    devicePushToken = window._savedPushToken || pushToken || '';
                }
                
                console.log("[Login] 안드로이드 - 로그인 요청, push_id:", devicePushToken);
                response = await loginUser(id, password, autoLogin, devicePushToken);
            }
            // 웹 환경 처리
            else {
                console.log("[Login] 웹 환경 감지");
                response = await loginUser(id, password, autoLogin, '');
            }
            
            // 공통 로그인 응답 처리 코드
            console.log("[Login] 로그인 응답:", response);
            
            if (response?.data?.code === 200) {
                const {access_token} = response.data.data;
                
                // 1. 토큰 유효성 검증
                const decodedToken = decodeToken(access_token);
                if (!decodedToken) {
                    setError('유효하지 않은 토큰입니다.');
                    return;
                }

                // 2. 토큰 만료 시간 확인
                const remainingTime = getRemainingTime(decodedToken);
                if (remainingTime <= 0) {
                    setError('만료된 토큰입니다.');
                    return;
                }

                // 3. 토큰 저장 (여러 저장소에 동시에 저장)
                localStorage.setItem('accessToken', access_token);
                sessionStorage.setItem('accessToken', access_token);
                setCookie('access_token', access_token, 30);

                const userInfo = {
                    ...decodedToken,
                    user_name: decodedToken.username,
                    email: response.data.data.email
                };

                // 4. 사용자 정보 저장
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                
                // 5. Zustand 스토어 업데이트
                useUserStore.getState().updateTokens(access_token);
                useUserStore.getState().updateUserInfo(userInfo);

                // 6. 자동 로그인 정보 저장
                if (autoLogin) {
                    localStorage.setItem('autoLogin', 'true');
                    localStorage.setItem('savedId', id);
                    localStorage.setItem('savedPassword', btoa(password));
                }

                // 7. SNS 로그인 여부 저장
                localStorage.setItem('loginType', 'email');

                navigate('/');
            } else {
                setError(response?.data?.message || '로그인에 실패했습니다.');
            }
        } catch (error) {
            console.error("[Login] 로그인 오류:", error);
            setError(error.response?.data?.message || '아이디 또는 비밀번호를 확인해주세요.');
        }
    };

    // SNS 로그인 버튼 클릭 핸들러도 유사하게 수정
    const handleSnsLoginWithToken = async (loginHandler) => {
        // 웹뷰 환경 확인
        const isInApp = (() => {
            const userAgent = navigator.userAgent.toLowerCase();
            return /android/i.test(userAgent) || 
                    (/iphone|ipad|ipod/i.test(userAgent) && /webview|wkwebview/i.test(userAgent));
        })();
        
        // 앱 환경에서만 푸시 토큰 요청
        if (isInApp) {
            console.log("[Login] SNS 로그인 - 푸시 토큰 요청");
            requestPushToken();
            // 토큰을 기다리지는 않고 진행 (SNS 로그인 과정에서 처리됨)
        }
        
        // 원래 로그인 핸들러 호출
        setTimeout(() => {
            console.log("[Login] SNS 로그인 핸들러 호출");
            loginHandler();
        }, 300);
    };

    const isValidForm = loginForm.id.trim() && loginForm.password.trim();

    return (
        <div className="login">
            <div className="content">
                <Header title="로그인" handleBack={() => window.history.back()}/>

                {/* 로고 섹션 */}
                <div className="login_logo_container">
                    <div className="login_logo_image">
                        <img src={MainLogo} alt="MainLogo" className="MainLogo"/>
                    </div>
                    <div className="login_logo_text_container">
                        <div className="login_logo_text">우리 아이 몸맘뇌 케어</div>
                        <div className="login_logo_title">아이큐비타민</div>
                    </div>
                </div>

                <div className="content_body" style={{marginTop: "40px", padding: "0px 24px"}}>
                    {/* 로그인 폼 */}
                    <div className="nickname_input_container default_input" style={{marginTop: "0px"}}>

                        <input
                            type="text"
                            name="id"
                            className="login_input"
                            value={loginForm.id}
                            onChange={handleInputChange}
                            placeholder="아이디 입력"
                        />

                        <div className="comb_input" style={{marginTop: "8px"}}>
                            <input
                                type={isShowPw ? 'text' : 'password'}
                                name="password"
                                className="login_input"
                                value={loginForm.password}
                                onChange={handleInputChange}
                                placeholder="비밀번호 입력"

                            />
                            <button 
                                    type="button" 
                                    className={`input_btn show_pw_btn ${isShowPw ? 'show' : ''}`}
                                    onClick={() => setShowPw(!isShowPw)}
                                    onMouseDown={(e) => passwordvisible(e, loginForm.password)}
                                ></button>
                        </div>

                        {error &&
                            <div className={`error_message nickname`}>{error}
                            </div>}
                        {/* 자동 로그인 체크박스 */}
                        <div className="default_chk_box auto_login">
                            <input name="autoLogin" type="checkbox" id="auto-login"
                                    onChange={handleInputChange}></input>
                            <label className="auto_login_title" htmlFor="auto-login">
                                <span>자동 로그인</span>
                            </label>
                        </div>
                    </div>

                    {/* 계정 관련 링크 */}
                    <div className="account_actions">
                        <Link to="/idfind" className="account_action">아이디 찾기</Link>
                        <img src={loginRectangle} alt="구분선"/>
                        <Link to="/passwordfind" className="account_action">비밀번호 찾기</Link>
                        <img src={loginRectangle} alt="구분선"/>
                        <Link to="/join" className="account_action">회원가입</Link>
                    </div>

                    {/* SNS 로그인 섹션 */}
                    <div className="login_footer">
                        <div className="sns-login-container">
                            <span>SNS 계정으로 로그인</span>
                        </div>
                        <div className="sns_login_buttons">
                            <div className="sns_login_button" onClick={() => handleSnsLoginWithToken(appleLoginInfo.handleAppleLogin)}>
                                <img className="NaverLogin" src={AppleLogin} alt="Apple 로그인"/>
                            </div>
                            <div className="sns_login_button" onClick={() => handleSnsLoginWithToken(naverLoginInfo.handleNaverLogin)}>
                                <img className="NaverLogin" src={NaverLogin} alt="Naver 로그인"/>
                                <div id="naverIdLogin" style={{display: 'none'}}></div>
                            </div>
                            <div className="sns_login_button" onClick={() => handleSnsLoginWithToken(kakaoLoginInfo.handleKakaoLogin)}>
                                <img className="KakaoLogin" src={KakaoLogin} alt="Kakao 로그인"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 로그인 버튼 */}
                <div className="Edit_btn_change">
                    <Button
                        label="로그인"
                        variant="btn_next"
                        onClick={handleLogin}
                        disabled={!isValidForm}
                        className={isValidForm ? 'active' : 'disabled'}
                    />
                </div>
            </div>
        </div>
    );
}

export default Login;

