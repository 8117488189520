import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import Header from "../../../components/Header/Header";
import Badge from "../../../components/Badge/Badge";
import { GENERAL_LIST_URL } from "../../../utils/urls";
import instance from "../../../services/services";


function GeneralTest() {
    const [data, setData] = useState(null); 
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await instance.post(GENERAL_LIST_URL, {
                    goods_type: "general" // 일반 검사
                });

                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error('데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('API 요청 오류:', error);
            }
        };

        fetchData();
    }, []);

    const navigate = useNavigate()
    const testTypeList = data ? [
        {
            user: data[0].goods_name,
            ageRange: data[0].goods_name_sub,
            isActive: true,
            url: '/childrenpayment',
            dueDate: ''
        },
        {
            user: '청소년',
            ageRange: '만 13세~만 19세',
            isActive: false,
            url: '',
            dueDate: '25년 6월'
        },
        {
            user: '성인',
            ageRange: '만 20세~만 59세',
            isActive: false,
            url: '',
            dueDate: '25년 6월'
        },
        {
            user: '시니어',
            ageRange: '만 60세 이상',
            isActive: false,
            url: '',
            dueDate: '25년 6월'
        }
    ] : [];

    const handleBackClick = () => {
        window.history.back();
    }
    const handleClick = () => {
        if (data && data.length > 0) { // data가 존재하고 배열에 요소가 있을 경우 보냄 
            navigate('/childrenpayment', { state: { goods_seq: data[0].goods_seq } }); 
        }
    };

    return (
        <div className="generalTest">
            <div className="content">

                <Header title="일반 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-50" badgeText="일반"/>
                        <div className="intro_description_test">
                            <div className="test_title">심리 발달 탄력성 검사</div>
                            <div className="test_info">심리 발달 탄력성은 심리 사회성 발달에 있어 극복과 도약의 에너지를 나타내는 것으로 부정 심리 사회성 발달과 경험에
                                대해 긍정적으로 이겨내고 수용하는 힘입니다.
                                <br/>
                                <br/>
                                심리 발달 탄력성이 좋은 아이일수록 자신에게 생겨나는 어렵고 힘든 상황을 쉽게 이겨내고 오히려 도전하는 긍정 에너지로 승화하려는 능력으로 발달하게 됩니다.
                                <br/>
                                <br/>
                                그러므로 우리 아이의 심리 사회성 발달의 정도 및 변화에 대해 체크하는 것은 중요합니다.
                            </div>

                        </div>
                    </div>
                    <div className="age_selection">
                        <div className="age_selection_title">검사 연령을 선택해 주세요</div>

                        <div className="age_options">
                            {testTypeList.map((test, index) => (
                                <button type="button"
                                        key={test.user}
                                        className="age_group_item"
                                        disabled={!test.isActive}
                                        onClick={(event) => handleClick(event, test)}>
                                    <div className="age_label">{test.user}</div>
                                    <div className="age_range">{test.ageRange}</div>
                                    {test.dueDate && !test.isActive&& (
                                        <div className="due_date"><span className="date">{test.dueDate} </span> 오픈예정</div>
                                    )}

                                </button>

                            ))}

                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralTest;
