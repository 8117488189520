import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Badge from "../../../components/Badge/Badge";
import Header from "../../../components/Header/Header";
import { formatLocalString } from "../../../utils/validation";
import instance from "../../../services/services";
import { GENERAL_GOODS_URL, TOSS_PAYMENT_URL } from "../../../utils/urls";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import useUserStore from "../../../store/user/userStore";

function SynthesisPayment() {
    const location = useLocation();
    const goodsSeq = location.state?.goods_seq;
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { memberTokens } = useUserStore();

    useEffect(() => {
        const fetchSynthesisData = async () => {
            try {
                const response = await instance.post(GENERAL_GOODS_URL, {
                    goods_seq: goodsSeq
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error("데이터 조회 실패");
                }
            } catch (error) {
                console.error("error 발생");
            }
        };
        fetchSynthesisData();
    }, [goodsSeq]);


    const handleBackClick = () => {
        window.history.back();
    }
    
    const handleDirectPurchase = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken') || memberTokens;

            if (!storedToken || !data) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login');
                return;
            }

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: Number(data.price),
                order_kind: "compr",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: data.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: data.goods_name,
                    inspection_title: data.inspection_title,
                    original_price: data.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody);

            if (response.data.code === 200) {
                const paymentInfo = response.data.data.data;
                const clientKey = process.env.NODE_ENV === 'development' 
                ? 'live_ck_DLJOpm5QrlyM1Babwl2A8PNdxbWn'
                : process.env.REACT_APP_TOSS_CLIENT_KEY;

                const tossPayments = await loadTossPayments(clientKey);
                await tossPayments.requestPayment('카드', {
                    amount: paymentInfo.amount,
                    orderId: paymentInfo.orderId,
                    orderName: paymentInfo.orderName,
                    customerName: paymentInfo.customerName,
                    successUrl: `${window.location.origin}/payment/result?goods_seq=${data.goods_seq}&order_kind=compr&redirect_path=/my-list&redirect_params=type=test`,
                    failUrl: `${window.location.origin}/payment/result?fail_redirect_path=/synthesispayment&fail_redirect_params=goods_seq=${data.goods_seq}`
                });
            } else {
                throw new Error(response.data.message || '결제 요청이 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            navigate(`/synthesispayment?goods_seq=${data.goods_seq}`);
        }
    };

    return (
        <div className="childrenDetail">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick}/>

                <div className="intro_body">
                    <div className="intro_section">
                        <Badge badgeColor="secondary-50" badgeText="종합"/>
                        <div className="intro_age_group">{data.goods_name}</div>
                    </div>
                </div>
                <div className="line_height"/>

                <div className="assessment_body">
                    {data.package_goods && data.package_goods.map((item, index) => (
                        <div className="synthesis_section" key={index}>
                            <div className="synthesis_info">
                                <div className="assessment_details">
                                    <div className="assessment_title ellipsis1">{item.goods_name}</div>
                                    <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                        <div className="assessment_question_count">{item.qstCnt} 문항</div>
                                    </div> 
                                </div>
                                <div className="children_image_container">
                                    <img 
                                        className="Children_Img" 
                                        src={item.mainImage} 
                                        alt="Children_Img" 
                                    />
                                </div>
                            </div> 
                            <div className="text_line"/>
                        </div>
                    ))}

                    <div className="price_info">
                        <div className="price_label">가격정보</div>
                        <div className="price_value">
                            {data.price ? `${formatLocalString(data?.price)}원` : '0원'}
                        </div>
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button 
                        label="바로 구매하기" 
                        variant="btn_complete" 
                        onClick={handleDirectPurchase}
                    />
                </div>
            </div>
        </div>
    );
}

export default SynthesisPayment;
