import React, { useEffect, useState } from 'react';
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useLocation } from 'react-router-dom';
import { INSPECTION_REPORT_URL } from '../../utils/urls';
import instance from '../../services/services';
import CoverReport from '../../components/Roport/CoverReport';
import CoverFairytale from '../../components/Roport/CoverFairytale';

Chart.register(annotationPlugin);

function AppReport() {
    const [reportData, setReportData] = useState(null);
    const [reportComponent, setReportComponent] = useState(null);
    const [reportDetailComponent, setReportDetailComponent] = useState(null);
    const [reportDetail2Component, setReportDetail2Component] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reportImages, setReportImages] = useState({});
    const location = useLocation();

    // URL에서 파라미터 추출 및 데이터 로딩
    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        try {
            // URL에서 파라미터 추출
            const urlParams = new URLSearchParams(location.search);
            const hash = urlParams.get('hash');
            const sortNum = urlParams.get('sort_num');
            const goodsType = urlParams.get('goods_type');
            const reportSeq = urlParams.get('report_seq');
            
            console.log('URL 파라미터:', { hash, sortNum, goodsType, reportSeq });
            
            if (!hash) {
            throw new Error('해시값이 없습니다');
            }
            
            // 1. 해시값으로 데이터 가져오기
            const response = await instance.get(`${INSPECTION_REPORT_URL}?hash=${hash}`);
            if (!response.data || !response.data.data) {
            throw new Error('리포트 데이터를 가져오지 못했습니다');
            }
            
            const fetchedData = response.data.data;
            fetchedData.sort_num = sortNum; // sort_num을 데이터에 포함
            fetchedData.goods_type = goodsType; // goods_type을 데이터에 포함
            fetchedData.report_seq = reportSeq; // report_seq도 포함
            setReportData(fetchedData);
            
            // 2. 동적으로 컴포넌트들 로드
            try {
            // 메인 리포트 컴포넌트
            const ReportModule = await import(`../../pages/Report/Report/${sortNum}`);
            setReportComponent(() => ReportModule.default);
            
            // 리포트 상세 컴포넌트
            try {
                const DetailModule = await import(`../../pages/Report/ReportDetail/${sortNum}`);
                setReportDetailComponent(() => DetailModule.default);
            } catch (detailError) {
                console.log('리포트 상세 컴포넌트 없음:', detailError);
            }
            
            // 리포트 상세2 컴포넌트
            try {
                const Detail2Module = await import(`../../pages/Report/ReportDetail2/${sortNum}`);
                setReportDetail2Component(() => Detail2Module.default);
            } catch (detail2Error) {
                console.log('리포트 상세2 컴포넌트 없음:', detail2Error);
            }
            
            // 3. 이미지 로드 (정적 이미지 경로 사용)
            const images = {};
            
            try {
                const reportImageModule = await import(`../../assets/report/${sortNum}.png`);
                images.report = reportImageModule.default;
            } catch (imgError) {
                console.log('리포트 이미지 없음:', imgError);
            }
            
            try {
                const report2ImageModule = await import(`../../assets/report2/${sortNum}.png`);
                images.report2 = report2ImageModule.default;
            } catch (img2Error) {
                console.log('리포트2 이미지 없음:', img2Error);
            }
            
            try {
                const report3ImageModule = await import(`../../assets/report3/${sortNum}.png`);
                images.report3 = report3ImageModule.default;
            } catch (img3Error) {
                console.log('리포트3 이미지 없음:', img3Error);
            }
            
            setReportImages(images);
            
            } catch (componentError) {
            console.error('컴포넌트 로드 실패:', componentError);
            // 컴포넌트 로드 실패해도 계속 진행 (나머지 UI는 표시)
            }
        } catch (err) {
            console.error('데이터 로드 오류:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
        };

        fetchData();
    }, [location]);

    // 리액트 네이티브 웹뷰일 경우 페이지 로드 완료 시 메시지 전송
    useEffect(() => {
        // const isInApp = !!window.ReactNativeWebView;
        const isAndroid = /Android/.test(navigator.userAgent);
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        
        if (!loading && reportData && window.ReactNativeWebView) {
        console.log(`앱 환경 감지: ${isIOS ? 'iOS' : ''} ${isAndroid ? 'Android' : ''}`);
        
        window.ReactNativeWebView.postMessage(JSON.stringify({
            key: "REPORT_LOADED",
            value: {
            report_seq: reportData.report_seq,
            sort_num: reportData.sort_num,
            platform: isAndroid ? 'android' : 'ios'
            }
        }));
        }
    }, [loading, reportData]);

    // 로딩 중이면 로딩 UI 표시
    if (loading) {
        return <div className="loading-container">리포트를 불러오는 중입니다...</div>;
    }

    // 오류가 있으면 오류 메시지 표시
    if (error) {
        return <div className="error-container">오류: {error}</div>;
    }

    // 데이터가 없으면 메시지 표시
    if (!reportData) {
        return <div className="error-container">리포트 데이터를 찾을 수 없습니다.</div>;
    }

    // 리포트 데이터가 있으면 컴포넌트들 렌더링
    const goodsType = reportData.goods_type;
    
    return (
        <div className="report-container">
        {/* 1. 표지 */}
        <div className="report-section" id="cover-page">
            {goodsType === 'story' ? (
            <CoverFairytale reportData={reportData} listData={[reportData]} />
            ) : (
            <CoverReport reportData={reportData} listData={[reportData]} />
            )}
        </div>
        
        {/* 2. 리포트 이미지 */}
        {reportImages.report && (
            <div className="report-section" id="image-page">
            <img 
                src={reportImages.report} 
                alt="리포트 이미지" 
                style={{ width: '100%', maxWidth: '100%' }} 
            />
            </div>
        )}
        
        {/* 3. 리포트 컴포넌트 */}
        {reportComponent && (
            <div className="report-section" id="report-page">
            {React.createElement(reportComponent, { 
                reportData: reportData,
                listData: [reportData]
            })}
            </div>
        )}
        
        {/* 4. 리포트 상세 컴포넌트 */}
        {reportDetailComponent && (
            <div className="report-section" id="report-detail-page">
            {React.createElement(reportDetailComponent, { 
                reportData: reportData 
            })}
            </div>
        )}
        
        {/* 5. 리포트 상세2 컴포넌트 */}
        {reportDetail2Component && (
            <div className="report-section" id="report-detail2-page">
            {React.createElement(reportDetail2Component, { 
                reportData: reportData 
            })}
            </div>
        )}
        
        {/* 6. 리포트2, 리포트3 이미지 페이지 */}
        {reportImages.report2 && (
            <div className="report-section" id="image2-page">
            <img 
                src={reportImages.report2} 
                alt="리포트2 이미지" 
                style={{ width: '100%', maxWidth: '100%' }} 
            />
            </div>
        )}
        
        {reportImages.report3 && (
            <div className="report-section" id="image3-page">
            <img 
                src={reportImages.report3} 
                alt="리포트3 이미지" 
                style={{ width: '100%', maxWidth: '100%' }} 
            />
            </div>
        )}
        </div>
    );
    }

export default AppReport;