import React from 'react';

// 2지선다 컴포넌트
const BinaryOptions = ({ index, selectedCheck, onNumberCheck, goods_seq, options }) => {
    // goods_seq가 82일 때의 특별한 형태
    if (goods_seq === '82') {
        return (
            <div className="response1-container">
                <div 
                    className="response-option1"
                    onClick={() => onNumberCheck(index, 1)}
                >
                    <div className={`response-score1 ${selectedCheck === 1 ? "activeCheck" : ""}`}>
                        A
                    </div>
                    <div style={{textAlign:"left", marginTop:"10px",padding:"0px 15px", color:"#08969D"}}>
                        {options?.[0]?.content || ''}
                    </div>
                </div>
                <div 
                    className="response-option1"
                    onClick={() => onNumberCheck(index, 2)}
                >
                    <div className={`response-score1 ${selectedCheck === 2 ? "activeCheck" : ""}`}>
                        B
                    </div>
                    <div style={{textAlign:"left", marginTop:"10px",padding:"0px 15px", color:"#08969D"}}>
                        {options?.[1]?.content || ''}
                    </div>
                </div>
            </div>
        );
    }

    // 기본 2지선다 형태
    return (
        <div className="response1-container">
            <div 
                className="response-option1"
                onClick={() => onNumberCheck(index, 1)}
            >
                <div className={`response-score1 ${selectedCheck === 1 ? "activeCheck" : ""}`}>
                    그렇다
                </div>
            </div>
            <div 
                className="response-option1"
                onClick={() => onNumberCheck(index, 2)}
            >
                <div className={`response-score1 ${selectedCheck === 2 ? "activeCheck" : ""}`}>
                    아니다
                </div>
            </div>
        </div>
    );
};

// 3지선다 컴포넌트
const ThreePointOptions = ({ index, selectedCheck, onNumberCheck }) => (
    <div className="three-point-container">
        <div 
            className={`three-point-option ${selectedCheck === 1 ? 'activeCheck' : ''}`}
            onClick={() => onNumberCheck(index, 1)}
        >
            O
        </div>
        <div 
            className={`three-point-option ${selectedCheck === 2 ? 'activeCheck' : ''}`}
            onClick={() => onNumberCheck(index, 2)}
        >
            △
        </div>
        <div 
            className={`three-point-option ${selectedCheck === 3 ? 'activeCheck' : ''}`}
            onClick={() => onNumberCheck(index, 3)}
        >
            X
        </div>
    </div>
);

// 5지선다 컴포넌트
const FivePointOptions = ({ index, selectedCheck, onNumberCheck }) => {
    console.log('FivePointOptions selectedCheck:', selectedCheck); // 디버깅
    return (
        <div className="response-container">
            {[1, 2, 3, 4, 5].map((score) => (
                <div 
                    key={score}
                    className="response-option"
                    onClick={() => onNumberCheck(index, score)}
                >
                    <div className={`response-score ${selectedCheck === score ? "activeCheck" : ""}`}>
                        {score}
                    </div>
                    <div className="response-label">
                        {score === 1 ? (
                            <>
                                <span>전혀</span>
                                <br />
                                <span>아니다</span>
                            </>
                        ) : score === 2 ? (
                            <span>아니다</span>
                        ) : score === 3 ? (
                            <span>보통</span>
                        ) : score === 4 ? (
                            <span>그렇다</span>
                        ) : (
                            <>
                                <span>매우</span>
                                <br />
                                <span>그렇다</span>
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

// 질문 컴포넌트
const Question = ({ question, index, selectedCheck, onNumberCheck, questionType, goods_seq, options }) => {
    console.log(goods_seq)
    console.log(question,'::::1111question');
    const isQuestion1 = questionType === 'binary';
    
    return (
        <div className={`question-container ${isQuestion1 ? "question1" : "question"}`}>
            <div className={`question-number ${isQuestion1 ? "question1-number" : "question-number"}`}>
                {question.count}
            </div>
            <div className={`question-text ${isQuestion1 ? "question1-text" : "question-text"}`}>
                {question.content}
            </div>
            
            {questionType === 'binary' && (
                <BinaryOptions 
                    index={index}
                    selectedCheck={selectedCheck}
                    onNumberCheck={onNumberCheck}
                    goods_seq={goods_seq}
                    options={options}
                />
            )}
            
            {questionType === 'threePoint' && (
                <ThreePointOptions 
                    index={index}
                    selectedCheck={selectedCheck}
                    onNumberCheck={onNumberCheck}
                />
            )}
            
            {questionType === 'fivePoint' && (
                <FivePointOptions 
                    index={index}
                    selectedCheck={selectedCheck}
                    onNumberCheck={onNumberCheck}
                />
            )}
        </div>
    );
};

// 질문 리스트 컴포넌트
const QuestionList = ({ questions, selectedCheck, onNumberCheck, questionType, goods_seq, options }) => {
    // 답변 데이터 구조 통일을 위한 헬퍼 함수
    const getSelectedValue = (question, index) => {
        // 객체 형태로 저장된 경우 (SynthesisTestDetail)
        if (typeof selectedCheck === 'object' && !Array.isArray(selectedCheck)) {
            return selectedCheck[index];
        }
        // qst_seq로 저장된 경우 (AfterVideoQuestions)
        if (question.qst_seq) {
            return selectedCheck;
        }
        // 기본값
        return selectedCheck?.[index];
    };

    return (
        <div className="question1_container">
            {questions && questions.length > 0 && questions[0].title && (
                <div className="question-title">{questions[0].title}</div>
            )}
            {goods_seq === "50" && questions.length > 0 && questions[0].qst_seq === "1988" && (
                <div className="question-section-title">
                    <p>지속성(만성) 운동 / 음성 틱 장애 영역</p>
                </div>
            )}
            
            {questions && questions.map((question, index) => (
                <Question
                    key={index}
                    question={question}
                    index={index}
                    selectedCheck={getSelectedValue(question, index)}
                    onNumberCheck={onNumberCheck}
                    questionType={questionType}
                    goods_seq={goods_seq}
                    options={options?.[index]}
                />
            ))}
        </div>
    );
};

export default QuestionList;