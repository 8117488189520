import React, { useRef, useEffect, useMemo } from 'react';
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
// import { useReportData } from '../../services/Report/report';
Chart.register(annotationPlugin);

function Report({ reportData }) {
  console.log(reportData,':::reportData321345')
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // const { reportResult, loading, error } = useReportData();

  // 하드코딩된 차트 데이터를 useMemo로 감싸기
  const data = useMemo(
    () => ({
      labels: [0, 0, 0, 0],
      datasets: [
        {
          data: [85, 75, 90, 70, 80],
          backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
          borderWidth: 0,
        },
      ],
    }),
    []
  ); // 빈 의존성 배열은 데이터가 변경되지 않음을 의미

  // customPlugin은 그대로 유지
  const customPlugin = useMemo(
    () => ({
      id: 'moveBarsToFront',
      afterDatasetsDraw(chart) {
        chart.data.datasets.forEach((dataset, index) => {
          if (chart.isDatasetVisible(index)) {
            const meta = chart.getDatasetMeta(index);
            if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
              meta.dataset.draw(chart.ctx);
            }
            meta.data.forEach((element) => {
              if (element && typeof element.draw === 'function') {
                element.draw(chart.ctx);
              }
            });
          }
        });
      },
    }),
    []
  );

  // 차트 초기화
  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(1, 'rgba(230,245,252,1)');

    const config = {
      type: 'bar',
      data: data,
      options: {
        devicePixelRatio: window.devicePixelRatio,
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: {
            display: false,
            text: '유 · 아동 교조증 행동 검사 결과',
          },
          annotation: {
            annotations: [
              {
                type: 'box',
                xMin: 0,
                xMax: 100,
                backgroundColor: gradient,
                borderWidth: 0,
                label: {
                  content: '',
                  enabled: true,
                  position: 'center',
                },
              },
            ],
          },
        },
        scales: {
          x: {
            min: 0,
            max: 100,
            stepSize: 10,
            title: { display: false },
            ticks: {
              stepSize: 10,
              beginAtZero: true,
              font: {
                size: 10, // x축 숫자 폰트 크기 조절
              },
            },
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10, // y축 숫자 폰트 크기 조절
              },
            },
          },
        },
      },
      plugins: [customPlugin],
    };

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, customPlugin]);

  const reportLegendStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className="view-report">
      <div className="report-content">
        <div className="report-wrapper rw01">
          <div className="report-section chart-section">
            <div className="report-title">
              <div className="title-icon1"></div>종합 비교 결과<div className="title-icon2"></div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw02">
          <div className="report-border">
            <div className="report-result">
              <div className="report-result-title">주 양육자의 훈육 유형</div>
              <div className="report-graph">
                <canvas ref={chartRef} className="chart-canvas" width="400" height="120"></canvas>
              </div>
              <div className="report-legend" style={reportLegendStyle}>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#FFE3A8' }}></div>
                  <div className="legend-label">종합</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#66DFB8' }}></div>
                  <div className="legend-label">긍정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#72C0FF' }}></div>
                  <div className="legend-label">행동 유발 환경 영역 </div>
                </div>
              </div>
            </div>
            <div className="report-class">
              <span>31 ~ 52 낮은 수준</span>
              <span>53 ~ 80 보통 수준</span>
              <span>81 ~ 100 높은 수준</span>
            </div>
            {/* 상하위 테이블 부모 */}
            <div className="report-section table-section">
              {/* 상위 테이블 */}
              <div className="report-table  reportC3">
                <div className="summary">
                  아이의 지각 영역과 양육자의 지각 영역 중 더 높은 영역이 우세 영역이며, 두 비율의 차이가 21% 미만이면
                  <br />
                  또래 비교 행위에 대한 관점 차이가 임상 범위에 해당하지 않습니다.
                </div>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>원점수</th>
                      <th>백분율(%)</th>
                      <th>수준</th>
                      <th>하위 영역 결과</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>아이의 지각 영역</td>
                      <td>12</td>
                      <td>57</td>
                      <td>보통 수준</td>
                      <td rowSpan={2} className="line150">
                        에 대해 측정합니다. 일종의 강박 행동인 교조증은 스트레스 상황이나 부정적인 감정을 해소하기 위해
                        손톱을 물어뜯음으로써 스트레스 완화, 긴장 및 불안 감소 등 긍정적인 정서가 일시적으로 유발되는
                        것을 돕습니다. 하지만 신체를 손상하는 부적절한 행동에 따른 결과이므로, 고착되는 것을 예방하는
                        노력이
                      </td>
                    </tr>
                    <tr>
                      <td>양육자의 지각 영역</td>
                      <td>9</td>
                      <td>43</td>
                      <td>낮은 수준</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* 하위 테이블 */}
              <div className="report-table result-report">
                <div>
                  <div className="box_w"></div>
                  <div className="box_text">
                    <span>아이의 지각 지수와 양육자의 지각지수가</span>
                    <span className="bg_text">유사합니다.</span>
                  </div>
                </div>

                <table>
                  <tr>
                    <td>
                      양육자 지각 지수에
                      <br />
                      따른 행동 조언
                    </td>
                    <td className="line150">
                      양육자님은 현재 아이의 양육 과정에서 또래와의 비교 행위를 보이지 않습니다.
                    </td>
                  </tr>
                  <tr>
                    <td>참고사항</td>
                    <td className="line150">
                      에 대해 측정합니다. 일종의 강박 행동인 교조증은 스트레스 상황이나 부정적인 감정을 해소하기 위해
                      손톱을 물어뜯음으로써 스트레스 완화, 긴장 및 불안 감소 등 긍정적인 정서가 일시적으로 유발되는 것을
                      돕습니다. 하지만 신체를 손상하는 부적절한 행동에 따른 결과이므로, 고착되는 것을 예방하는 노력이
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw03"></div>
      </div>
    </div>
  );
}
export default Report;
