import React, {useEffect} from 'react';
import {useAppleLogin} from "../../hooks/login/snsLogin";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const AppleLoginButton = () => {
    const navigate = useNavigate();
    // Join 페이지와 Login 페이지에서 같은 컴포넌트를 사용하므로 현재 경로 확인
    const currentPath = window.location.pathname;
    const isLoginPage = currentPath === '/login';
    
    // 경로에 따라 다른 리다이렉트 URI 설정
    const redirectUri = isLoginPage 
        ? 'https://www.iqvitamininside.com/login' 
        : 'https://www.iqvitamininside.com/agreement';
    
    const {handleAppleLogin, snsInfo} = useAppleLogin(redirectUri);

    useEffect(() => {
        console.log('애플 로그인 정보:', snsInfo);
        console.log('현재 경로:', currentPath);
        
        if (snsInfo?.fromSns) {
            try {
                // 로그인 페이지에서는 바로 로그인 처리하지 않고 agreement 페이지로 이동
                if (!isLoginPage) {
                    console.log('회원가입 흐름: /agreement로 이동');
                    navigate('/agreement', { state: snsInfo });
                } else {
                    console.log('로그인 페이지에서는 useEffect에서 처리하지 않음');
                    // Login.js의 useEffect에서 처리됨
                }
            } catch (error) {
                console.error('네비게이션 오류:', error);
                toast('로그인 처리 중 오류가 발생했습니다.');
            }
        }
    }, [snsInfo, navigate, isLoginPage, currentPath]);

    const handleClick = () => {
        console.log('애플 로그인 버튼 클릭', currentPath);
        handleAppleLogin();
    };

    return (
        <button type="button" className="sns_btn left_icon apple" onClick={handleClick}>
            Apple로 시작하기
        </button>
    );
};

export default AppleLoginButton; 