import React, {useEffect, useMemo, useState} from "react";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import ShoppingPop from "../../components/Popup/ShoppingPop";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {SINGLE_TEST_URL, CART_URL} from "../../utils/urls";
import instance from "../../services/services";
import useUserStore from '../../store/user/userStore';
import { TOSS_PAYMENT_URL } from "../../utils/urls";
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { formatLocalString } from "../../utils/validation";
import { setCookie, getCookie } from "../../utils/cookies";


function SeriesSinglePayment() {
    const { memberTokens, updateTokens } = useUserStore();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [searchParams] = useSearchParams();
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [singleData, setSingleData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const goodsSeq = location.state?.goodsSeq || searchParams.get('goods_seq');

    useEffect(() => {
        const fetchSingleData = async () => {
            try {
                const errorMessage = searchParams.get('message');
                const errorCode = searchParams.get('code');
                
                if (errorMessage && errorCode) {
                    console.log('결제 실패:', errorMessage);
                }

                if (!goodsSeq) {
                    console.error('상품 정보가 없습니다');
                    return;
                }

                const response = await instance.post(SINGLE_TEST_URL, {
                    goods_seq: goodsSeq
                });

                if (response.data.code === 200) {
                    setSingleData(response.data.data);
                } else {
                    console.error('단일 상품 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('단일 상품 데이터 조회 오류:', error);
            }
        };

        fetchSingleData();
    }, [goodsSeq, searchParams]);

    // 토큰 검증 함수를 더 간단하게 수정
    // const verifyToken = async (token) => {
    //     try {
    //         // 기존 API 중에서 간단한 요청을 사용하여 토큰 유효성 검증
    //         const response = await instance.post('/api/member/info', {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             },
    //             withCredentials: true
    //         });
    //         return response.data.code === 200;
    //     } catch (error) {
    //         // 401 에러면 토큰이 유효하지 않은 것
    //         if (error.response?.status === 401) {
    //             return false;
    //         }
    //         // 다른 에러의 경우 일단 토큰이 있다면 유효하다고 가정
    //         return !!token;
    //     }
    // };

    // useEffect에서 초기 토큰 검증 제거
    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('accessToken') || memberTokens || getCookie('access_token');
            if (token) {
                // 토큰이 있으면 저장소 동기화만 수행
                localStorage.setItem('accessToken', token);
                sessionStorage.setItem('accessToken', token);
                setCookie('access_token', token, 30);
                updateTokens(token);
            }
        };

        validateToken();
    }, [memberTokens, updateTokens]);

    const handleShoppingClick = async () => {
        try {
            if (!memberTokens) {
                console.error('토큰이 없습니다');
                navigate('/login');
                return;
            }

            const cartData = {
                "goods_seq": String(goodsSeq),
                "ea": "1"
            };

            const response = await instance.post(CART_URL, cartData, {
                headers: {
                    'Authorization': `Bearer ${memberTokens}` // 헤더에 토큰값 포함시켜야함
                }
            });

            if (response.data.code === 200) {
                setIsOutPopOpen(true);
            } else {
                console.error('장바구니 담기 실패:', response.data.message);
            }
        } catch (error) {
            console.error('장바구니 API 호출 오류:', error);
        }
    };

    const handleBackClick = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    }

    const handleDirectPurchase = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('accessToken') || memberTokens || getCookie('access_token');
            
            if (!token || !singleData) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login', { 
                    state: { 
                        returnPath: `/callsinglepayment`,
                        goods_seq: goodsSeq 
                    } 
                });
                return;
            }

            // 토큰 저장소 동기화
            localStorage.setItem('accessToken', token);
            sessionStorage.setItem('accessToken', token);
            setCookie('access_token', token, 30);
            updateTokens(token);

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: Number(singleData.price),
                order_kind: "story",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: singleData.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: singleData.goods_name,
                    inspection_title: singleData.inspection_title,
                    original_price: singleData.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            
            if (response.data.code === 200 && response.data.data?.data) {
                const paymentInfo = response.data.data.data;
                const clientKey = 'live_ck_DLJOpm5QrlyM1Babwl2A8PNdxbWn';
                const baseUrl = 'https://iqvitamininside.com';

                const tossPayments = await loadTossPayments(clientKey);
                
                try {
                    await tossPayments.requestPayment('카드', {
                        amount: paymentInfo.amount,
                        orderId: paymentInfo.orderId,
                        orderName: paymentInfo.orderName,
                        customerName: paymentInfo.customerName,
                        successUrl: `${baseUrl}/payment/result?goods_seq=${singleData.goods_seq}&order_kind=story&redirect_path=/storyinformation&fail_redirect_path=/callsinglepayment&fail_redirect_params=goods_seq=${singleData.goods_seq}&backup_token=${encodeURIComponent(token)}`,
                        failUrl: `${baseUrl}/payment/result?goods_seq=${singleData.goods_seq}&redirect_path=/callsinglepayment&fail_redirect_params=goods_seq=${singleData.goods_seq}&backup_token=${encodeURIComponent(token)}`,
                        windowTarget: 'BLANK'
                    });
                } catch (paymentError) {
                    throw paymentError;
                }
            } else {
                throw new Error('결제 정보를 가져오는데 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            if (error.response?.status === 401) {
                alert('로그인이 필요합니다. 다시 로그인해주세요.');
                navigate('/login', { 
                    state: { 
                        returnPath: `/callsinglepayment`,
                        goods_seq: goodsSeq 
                    } 
                });
            } else {
                alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="childrenDetail">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-60" badgeText="동화" />
                        <div className="intro_payment_group">{singleData?.goods_name}</div>
                    </div>

                    <div className="assessment_section" style={{padding:"16px 0px"}}>
                        <div className="payment_info">
                            <div className="payment_title_container">
                                <div className="payment_title">{singleData?.story_title}</div>
                                <div className="assessment_subtitle" style={{paddingTop:"4px"}}>{singleData?.inspection_title}</div>
                                <div className="call_type_details" style={{paddingTop:"6px"}}>
                                    <span className="emotion_behavior">{singleData?.story_title_sub}</span>
                                    <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                                    <span className="k_cast">{singleData?.inspection_abbr}</span>
                                </div>
                            </div>
                            <div className="payment_image_container">
                                <img src={singleData?.image_url} alt="Payment_img" className="payment_img" />
                            </div>
                        </div>
                        <div className="payment_content">{singleData?.goods_detail}</div>
                    </div>

                    <div className="price_info">
                        <div className="price_label">가격정보</div>
                        <div className="price_value"> {singleData?.price ? `${formatLocalString(singleData.price)}원` : '0원'}</div>
                    </div>
                </div>

                <div className="Edit_btn_complete">
                    <Button  
                        label="장바구니 담기" 
                        variant="btn_join" 
                        onClick={handleShoppingClick}
                    />
                    <Button  
                        label="바로 구매하기" 
                        variant="btn_complete" 
                        onClick={handleDirectPurchase}
                        disabled={!singleData || isLoading}
                    />
                </div>
                <ShoppingPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                />
            </div>
        </div>
    );
}

export default SeriesSinglePayment;

