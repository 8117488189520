import instance from "../services";
import {JOIN_USERINFO_URL} from "../../utils/urls";
import axios from "axios";

export const joinUser = async ({
    userid,
    password,
    email,
    nickname,
    user_name,
    mem_phone,
    push,
    push_id,
    ci,
    di
}) => {
    try {
        console.log('회원가입 요청 데이터:', {
            userid,
            password,
            email,
            nickname,
            user_name,
            mem_phone,
            push,
            push_id,
            ci,
            di
        });

        const response = await instance.post(JOIN_USERINFO_URL, {
            userid,
            password,
            email,
            user_name,
            nickname,
            mem_phone,
            push,
            push_id,
            ci,
            di
        });

        return response;
    } catch (error) {
        console.error('회원가입 에러 상세:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        throw error;
    }
};

/**
 * 유저 아이디 중복 체크
 * @param userid
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const checkUserId = async ({userid}) => {
    try {
        return await instance.post('/api/myIdCheck', {userid});
    } catch (e) {
        console.error(e)
    }
}

/**
 * 유저 이메일 체크
 * @param userid
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const checkUserEmail = async ({email}) => {
    try {
        return await instance.post('/api/myEmailCheck', {email});
    } catch (e) {
        console.error(e)
    }
}

/**
 * 유저 닉네임  체크
 * @param userid
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const checkUserNickname = async ({nickname}) => {
    try {
        return await instance.post('/api/myNicknameCheck', {nickname});
    } catch (e) {
        console.error(e)
    }
}


/**
 * 네이버 회원 가입
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const joinNaverUser = async (params) => {
    try {
        return await instance.post('/api/auth/naver/register', params);
    } catch (e) {
        console.error(e)
    }
}


/**
 * 카카오톡 회원 가입
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const joinKakaoUser= async (params) => {
    try {
        return await instance.post('/api/auth/kakao/register', params);
    } catch (e) {
        console.error(e)
    }
}


/**
 * 애플 회원 가입
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const joinAppleUser = async (params) => {
    try {
        return await instance.post('/api/auth/apple/register', params);
    } catch (e) {
        console.error(e)
    }
}

/**
 * 네이버 로그인
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const loginNaverUser = async (params) => {
    try {
        return await instance.post('/api/auth/naver/login', params);
    } catch (e) {
        console.error(e)
    }
}


/**
 * 카카오톡 로그인
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const loginKakaoUser= async (params) => {
    try {
        return await instance.post('/api/auth/kakao/login', params);
    } catch (e) {
        console.error(e)
    }
}


/**
 * 애플 로그인
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const loginAppleUser = async (params) => {
    try {
        return await instance.post('/api/auth/apple/login', params);
    } catch (e) {
        console.error(e)
    }
}

/**
 * 애플 인증 코드로 토큰 얻기 함수 추가
 * @param authCode
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const appleAuthWithCode = async (authCode) => {
    try {
        const response = await axios.post('/api/auth/apple/token', { code: authCode });
        return response.data;
    } catch (error) {
        console.error('애플 코드 인증 실패:', error);
        throw error;
    }
};

