import { useState, useEffect, useCallback } from 'react';

// 토큰 상태를 저장할 전역 변수 추가
window._savedPushToken = '';

export const usePushToken = () => {
    const [pushToken, setPushToken] = useState('');
    const [isWaitingForToken, setIsWaitingForToken] = useState(false);
    
    // 푸시 토큰 요청 함수 
    const requestPushToken = useCallback(() => {
        try {
            console.log("[Push Token] 푸시 토큰 요청 시작");
            setIsWaitingForToken(true);
            
            // 앱에 토큰 요청
            window.postMessage({key: "DEVICE_TOKEN"}, "*");
            console.log("[Push Token] DEVICE_TOKEN 요청 메시지 전송됨");
            
        } catch (error) {
            console.error("[Push Token] 푸시 토큰 요청 오류:", error);
            setIsWaitingForToken(false);
        }
    }, []);

    // 토큰이 변경될 때마다 전역 변수에도 저장
    useEffect(() => {
        if (pushToken) {
            window._savedPushToken = pushToken;
        }
    }, [pushToken]);

    // 앱으로부터 푸시 토큰 수신 이벤트 리스너
    useEffect(() => {
        // iOS 확인
        const isIOS = (() => {
            const userAgent = navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/i.test(userAgent);
        })();
        
        console.log("[Push Token] 환경 감지:", isIOS ? "iOS" : "non-iOS");
        
        // 리스너 등록을 항상 수행하되, 콘솔에 상태 로깅
        if (window._pushTokenListenerRegistered) {
            console.log("[Push Token] 이미 이벤트 리스너가 등록되어 있습니다");
            // 기존 리스너 제거 후 재등록하는 방식으로 변경
            window.removeEventListener('message', window._savedListener);
            document.removeEventListener('message', window._savedListener);
            console.log("[Push Token] 기존 리스너 제거 완료");
        }

        const listener = (event) => {
            console.log("[Push Token] 메시지 이벤트 수신:", {
                type: typeof event.data,
                data: event.data,
                origin: event.origin,
                isIOS: isIOS
            });
            
            try {
                // 웹팩 메시지 무시
                if (typeof event.data === 'string' && 
                    (event.data.startsWith('webpackHot') || event.data.includes('webpack'))) {
                    return;
                }
                
                const data = event.data;
                
                // iOS는 문자열로 전달되는 경우가 많음
                if (isIOS && typeof event.data === 'string') {
                    // iOS 전용 파싱 로직 추가
                    try {
                        // 1. 일반 JSON 파싱 시도
                        let parsedData;
                        try {
                            parsedData = JSON.parse(event.data);
                        } catch (e) {
                            // 2. iOS에서 가끔 따옴표가 추가되는 경우 대비
                            const cleanData = event.data.replace(/^['"]|['"]$/g, '');
                            try {
                                parsedData = JSON.parse(cleanData);
                            } catch (e2) {
                                // 3. 마지막 시도 - 이스케이프된 JSON 문자열 처리
                                parsedData = JSON.parse(cleanData.replace(/\\"/g, '"'));
                            }
                        }
                        
                        if (parsedData && (parsedData.key === 'DEVICE_TOKEN' || parsedData.type === 'DEVICE_TOKEN')) {
                            const tokenValue = parsedData.value || parsedData.token || '';
                            setPushToken(tokenValue);
                            window._savedPushToken = tokenValue;
                            setIsWaitingForToken(false);
                            console.log("[Push Token] iOS 토큰 수신:", tokenValue);
                        }
                    } catch (e) {
                        console.error("[Push Token] iOS 데이터 파싱 실패:", e);
                    }
                } 
                else if (typeof data === 'object' && data !== null) {
                    if (data.key === 'DEVICE_TOKEN') {
                        // 중요: 토큰을 컴포넌트 상태와 전역 변수 모두에 저장
                        setPushToken(data.value);
                        window._savedPushToken = data.value;
                        setIsWaitingForToken(false);
                        
                        console.log("[Push Token] 토큰 수신(객체):", data.value);
                    }
                }
            } catch (error) {
                console.error("[Push Token] 메시지 처리 오류:", error);
                setIsWaitingForToken(false);
            }
        };

        // 리스너 함수 저장 (제거를 위해)
        window._savedListener = listener;
        
        // 이벤트 리스너 등록
        window.addEventListener('message', listener);
        document.addEventListener('message', listener); 
        
        // 중복 등록 방지 플래그 설정
        window._pushTokenListenerRegistered = true;
        
        return () => {
            // 리스너는 제거하지만 토큰은 유지
            window.removeEventListener('message', listener);
            document.removeEventListener('message', listener);
            window._pushTokenListenerRegistered = false;
        };
    }, []);

    // 토큰 초기화 함수
    const resetToken = useCallback(() => {
        setPushToken('');
        setIsWaitingForToken(false);
    }, []);

    return { 
        pushToken, 
        requestPushToken, 
        resetToken,
        isWaitingForToken
    };
};