import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionList from '../../components/QuestionTypes/QuestionComponent';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import instance from '../../services/services';
import { INSPECTION_ANSWER_URL } from '../../utils/urls';

function AfterVideoQuestions() {
    const { state } = useLocation();
    const navigate = useNavigate();

    console.log('전체 state:', state.after_questions);
    
    const getQuestionType = (answerType, goods_seq) => {
        console.log(goods_seq,'::::goods_seq')
        if (answerType === "2" || answerType === 2) return "binary";
        if (answerType === "3" || answerType === 3) return "threePoint";
        return "fivePoint";
    };

    const [answers, setAnswers] = useState({});

    // 모든 문항이 풀렸는지 확인하는 함수
    const isAllQuestionsAnswered = () => {
        const totalQuestions = state.after_questions.after_questions.length;
        const answeredQuestions = Object.keys(answers).length;
        return totalQuestions === answeredQuestions;
    };

    const handleNumberCheck = (questionIndex, selectedCheck) => {
        setAnswers(prev => ({
            ...prev,
            [questionIndex]: selectedCheck
        }));
        console.log('현재 선택된 답변들:', answers);
    };

    const handleSubmit = async () => {
        try {
            const formattedAnswers = Object.entries(answers).map(([qst_seq, value]) => {
                const question = state.after_questions.after_questions.find(q => q.qst_seq === qst_seq);
                
                return {
                    qst_seq: qst_seq,
                    answer_type: question.answer_type,
                    choice_val: value,
                    short_answer: ""
                };
            });

            // 비디오 답변과 사후 문항 답변 합치기
            const allAnswers = [...(state.video_answers || []), ...formattedAnswers];

            const requestBody = {
                order_seq: state.Data.orderId,
                item_option_seq: state.Data.item_option_seq,
                goods_seq: state.Data.goods_seq,
                age_txt: state.Data.age,
                gender: state.Data.gender,
                test_user_name: state.Data.name,
                answers: allAnswers
            };

            console.log('제출할 데이터:', requestBody);

            const response = await instance.post(INSPECTION_ANSWER_URL, requestBody);
            console.log('응답:', response.data);

            if (response.data.success) {
                navigate('/storycomplete', {
                    state: {
                        goods_seq: state.Data.goods_seq,
                        order_seq: state.Data.orderId,
                        item_option_seq: state.Data.item_option_seq,
                        goods_name: response.data.data.goods_name,
                        image_path: response.data.data.image_path,
                        report_seq: response.data.data.report_seq,
                        large_image: response.data.data.large_image,
                        sort_num: state.Data.sort_num
                    }
                });
            } else {
                alert(response.data.message);
            }

        } catch (error) {
            console.error('답변 제출 중 오류:', error);
            alert('답변 제출 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className="afterVideoQuestions">
            <div className="content">
                <Header 
                    title={state.Data.goods_name} 
                    handleBack={() => navigate(-1)}
                />
                
                <div className="content_body">
                    {state.after_questions.after_questions.map((question, index, array) => {
                        console.log(question,'::::question')
                        // goods_seq가 82이고 index가 12일 때(정확히 s_parent가 시작되는 지점)
                        const isFirstSParent = state.Data.goods_seq === "82" && index === 12;
                        // goods_seq가 85이고 index가 17일 때(18번 문항 위)
                        const isGoodsSeq85Parent = state.Data.goods_seq === "85" && index === 18;
                        // goods_seq가 50이고, qst_seq가 1988번
                        const isGoodsSeq50Parent = state.Data.goods_seq === "50" && question.qst_seq === "1995";
                        // goods_seq가 50이고, qst_seq가 2001번
                        const isGoodsSeq50Parent2 = state.Data.goods_seq === "50" && question.qst_seq === "2001";

                        const questionData = {
                            count: "Q-" + (index + 1),
                            content: question.qst_content,
                            title: index === 0 ? state.after_questions.after_questions_title : null,
                            qst_seq: question.qst_seq
                        };

                        // score_arr_txt를 options 형식으로 변환
                        const options = question.score_arr_txt ? [
                            { content: question.score_arr_txt[0] },
                            { content: question.score_arr_txt[1] }
                        ] : null;

                        return (
                            <React.Fragment key={question.qst_seq}>
                                {isFirstSParent && (
                                    <div className="question-section-title">
                                        <p>&lt;보조 양육자용&gt; 양육자의 검사가 끝나셨다면,</p>
                                        <p>보조 양육자가 검사를 진행해주세요.</p>
                                    </div>
                                )}
                                {isGoodsSeq85Parent && (
                                    <div className="question-section-title">
                                        <p>&lt;보조 양육자용&gt; 양육자의 검사가 끝나셨다면,</p>
                                        <p>보조 양육자가 검사를 진행해주세요.</p>
                                    </div>
                                )}
                                {isGoodsSeq50Parent && (
                                    <div className="question-section-title">
                                        <p>뚜렛 장애 영역</p>
                                    </div>  
                                )}
                                {isGoodsSeq50Parent2 && (
                                    <div className="question-section-title">
                                        <p>잠정적 틱 장애 영역</p>
                                    </div>  
                                )}
                                <QuestionList
                                    questions={[questionData]}
                                    selectedCheck={answers[question.qst_seq]}
                                    onNumberCheck={(_, value) => handleNumberCheck(question.qst_seq, value)}
                                    questionType={getQuestionType(question.answer_type, state.Data.goods_seq)}
                                    goods_seq={state.Data.goods_seq}
                                    options={[options]}  // 배열 형태로 전달
                                />
                            </React.Fragment>
                        );
                    })}
                </div>

                <div className="Edit_btn_complete">
                    <Button
                        label="다음"
                        className={isAllQuestionsAnswered() ? "active" : "disabled"}
                        variant="btn_change"
                        onClick={handleSubmit}
                        disabled={!isAllQuestionsAnswered()}
                    />
                </div>
            </div>
        </div>
    );
}

export default AfterVideoQuestions;