import axios from 'axios';
import useUserStore from '../store/user/userStore';
import {toast} from "react-toastify";
import {getRemainingTime} from "../utils/token";
import { getCookie } from '../utils/cookies';

// 리다이렉트 방지를 위한 플래그
let isRedirecting = false;

// 공개 API 목록 - 로그인이 필요하지 않은 API
const publicApis = [
    '/api/main/storylist', // 메인 페이지 스토리 목록
    '/api/board/list',     // 공지사항 목록
    // 다른 로그인 없이 접근 가능한 API 추가
];

export const instance = axios.create({
    baseURL: 'https://admin.iqvitamininside.com',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

// 요청 인터셉터 설정
instance.interceptors.request.use((config) => {
    // 공개 API인 경우 토큰 검사를 건너뜁니다
    const isPublicApi = publicApis.some(api => config.url.includes(api));
    
    const {memberTokens, logout, userInfo, refreshMemberToken} = useUserStore.getState(); // store에서 토큰값 가져옴
    
    // 토큰이 없을 경우 여러 저장소에서 확인
    const token = memberTokens || getCookie('access_token') || localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    
    // 모든 요청에 토큰이 있으면 헤더에 추가
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        
        // 비공개 API에만 토큰 유효성 검사를 적용
        if (!isPublicApi && userInfo) {
            const remainingTime = getRemainingTime(userInfo);
            
            // 토큰 만료
            if (remainingTime <= 0) {
                if (!isRedirecting) {
                    isRedirecting = true;
                    logout();
                    setTimeout(() => {
                        toast('로그인이 필요합니다.');
                        window.location.href = '/login';
                        isRedirecting = false;
                    }, 100);
                }
            }
            // 7일 기준 토큰 갱신
            else if (userInfo.keepLoginChk === 'Y') {
                const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
                if (remainingTime < sevenDaysInMs) {
                    refreshMemberToken();
                }
            }
        }
    }
    
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 응답 인터셉터 추가
instance.interceptors.response.use(
    (response) => {
        // 공개 API에 대한 응답인 경우 리다이렉트 하지 않음
        const isPublicApi = publicApis.some(api => response.config.url.includes(api));
        
        // 토큰 없음 또는 토큰 만료 처리
        if ((response.data.code === -300 || response.data.code === 301) && !isPublicApi) {
            if (!isRedirecting) {
                isRedirecting = true;
                useUserStore.getState().logout();
                setTimeout(() => {
                    toast('로그인이 필요합니다.');
                    window.location.href = '/login';
                    isRedirecting = false;
                }, 100);
            }
            return response;
        }
        return response;
    },
    (error) => {
        // 401 에러 처리 (인증 실패)
        if (error.response && error.response.status === 401) {
            const isPublicApi = publicApis.some(api => error.config.url.includes(api));
            
            if (!isPublicApi && !isRedirecting) {
                isRedirecting = true;
                useUserStore.getState().logout();
                setTimeout(() => {
                    toast('로그인이 필요합니다.');
                    window.location.href = '/login';
                    isRedirecting = false;
                }, 100);
            }
        } else {
            toast('페이지를 새로 고침하거나 잠시 후에 다시 시도해 주세요.');
        }
        return Promise.reject(error);
    }
);

export default instance;







// 기존 소스
// import axios from 'axios';
// import useUserStore from '../store/user/userStore';
// import {toast} from "react-toastify";
// import {getRemainingTime} from "../utils/token";
// import { getCookie } from '../utils/cookies';

// export const instance = axios.create({
//     baseURL: 'https://admin.iqvitamininside.com',
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     timeout: 10000,
// });

// // 요청 인터셉터 설정
// instance.interceptors.request.use((config) => {
//     const {memberTokens, logout, userInfo, refreshMemberToken} = useUserStore.getState(); // store에서 토큰값 가져옴

//     // 토큰이 없을 경우 localStorage에서 확인
//     const token = memberTokens || getCookie('access_token');

//     if (memberTokens) {
//         const remainingTime = getRemainingTime(userInfo);

//         // 토큰 만료
//         if (remainingTime <= 0) {
//             logout();
//             toast('로그인이 필요합니다.')
//             window.location.href = '/login';
//         }
//         // 7일 기준 토큰 갱신
//         if (userInfo.keepLoginChk === 'Y') {
//             const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000
//             if (remainingTime < sevenDaysInMs) {
//                 refreshMemberToken()
//             }
//         }

//     }
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// // 응답 인터셉터 추가
// instance.interceptors.response.use(
//     (response) => {
// //토큰 없음
//         if (response.data.code === -300) {
//             toast('로그인이 필요합니다.')
//             window.location.href = '/login';
//         }
// // 토큰 만료
//         if (response.data.code === 301) {
//             toast('로그인이 필요합니다.')
//             window.location.href = '/login';
//         }
//         return response;
//     },
//     (error) => {
//         toast('페이지를 새로 고침하거나 잠시 후에 다시 시도해 주세요.')
//         return Promise.reject(error);
//     }
// );

// export default instance;