import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function ReportDetailD1() {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartInstance1 = useRef(null);
  const chartInstance2 = useRef(null);

  // 첫 번째 차트 데이터
  const data1 = useMemo(
    () => ({
      labels: [0, 0, 0, 0],
      datasets: [
        {
          data: [85, 75, 90, 70],
          backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
          borderWidth: 0,
        },
      ],
    }),
    []
  );

  // 두 번째 차트 데이터
  const data2 = useMemo(
    () => ({
      labels: [0, 0, 0, 0],
      datasets: [
        {
          data: [65, 85, 70, 80],
          backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
          borderWidth: 0,
        },
      ],
    }),
    []
  );

  const customPlugin = useMemo(
    () => ({
      id: 'moveBarsToFront',
      afterDatasetsDraw(chart) {
        chart.data.datasets.forEach((dataset, index) => {
          if (chart.isDatasetVisible(index)) {
            const meta = chart.getDatasetMeta(index);
            if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
              meta.dataset.draw(chart.ctx);
            }
            meta.data.forEach((element) => {
              if (element && typeof element.draw === 'function') {
                element.draw(chart.ctx);
              }
            });
          }
        });
      },
    }),
    []
  );

  const initializeChart = useCallback(
    (ref, instance, data, title) => {
      if (!ref.current) return;

      if (instance.current) {
        instance.current.destroy();
      }

      const ctx = ref.current.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
      gradient.addColorStop(0, 'rgba(255,255,255,1)');
      gradient.addColorStop(1, 'rgba(230,245,252,1)');

      const config = {
        type: 'bar',
        data: data,
        options: {
          devicePixelRatio: window.devicePixelRatio,
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            title: {
              display: false,
              text: title,
            },
            annotation: {
              annotations: [
                {
                  type: 'box',
                  xMin: 0,
                  xMax: 100,
                  backgroundColor: gradient,
                  borderWidth: 0,
                  label: {
                    content: '',
                    enabled: true,
                    position: 'center',
                  },
                },
              ],
            },
          },
          scales: {
            // ... 기존 scales 설정
          },
        },
        plugins: [customPlugin],
      };

      instance.current = new Chart(ctx, config);
    },
    [customPlugin]
  ); // customPlugin만 의존성으로 추가

  useEffect(() => {
    const chart1 = chartInstance1.current;
    const chart2 = chartInstance2.current;

    initializeChart(chartRef1, chartInstance1, data1, '타인 의도 상황 수용 능력 검사 결과');
    initializeChart(chartRef2, chartInstance2, data2, '타인 사고 상황 수용 능력 검사 결과');

    return () => {
      if (chart1) chart1.destroy();
      if (chart2) chart2.destroy();
    };
  }, [data1, data2, customPlugin, initializeChart]); // initializeChart 추가

  return (
    <div className="view-report">
      <div className="report-content">
        <div className="report-wrapper rw01">
          <div className="report-section chart-section">
            <div className="report-title">
              <div className="title-icon1"></div>
                상세 결과
                <div className="title-icon2"></div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw02">
          {/* 첫번째 컨텐츠 */}
          <div className="report-border">
            <div className="report-result">
              <div className="report-result-title">타인 의도 상황 수용 능력 검사 결과</div>
              <div className="report-graph">
                <canvas ref={chartRef1} className="chart-canvas" width="400"></canvas>
              </div>
              <div className="report-legend">
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#FFE3A8' }}></div>
                  <div className="legend-label">종합</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#66DFB8' }}></div>
                  <div className="legend-label">긍정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#72C0FF' }}></div>
                  <div className="legend-label">행동 유발 환경 영역</div>
                </div>
              </div>
            </div>
            <div className="report-class">
              <span>0 ~ 25 낮은 수준 / </span>
              <span>26 ~ 75 보통 수준 / </span>
              <span>76 ~ 100 높은 수준 </span>
            </div>
            <div className="report-section table-section">
              <div className="report-table result-report">
                <div className="box_text">
                  <span>아동의 타인 의도 상황 수용 능력은</span>
                  <span className="bg_text">보통 (평균) 상태</span>
                  <span>입니다.</span>
                </div>
                <div className="desc_box">
                  에 대해 측정합니다. 일종의 강박 행동인 교조증은 스트레스 상황이나 부정적인 감정을 해소하기 위해 손톱을
                  물어뜯음으로써 스트레스 완화, 긴장 및 불안 감소 등 긍정적인 정서가 일시적으로 유발되는 것을 돕습니다.
                  하지만 신체를 손상하는 부적절한 행동에 따른 결과이므로, 고착되는 것을 예방하는 노력이
                </div>
              </div>
            </div>
          </div>
          {/* 두번째 컨텐츠 */}
          <div className="report-border">
            <div className="report-result">
              <div className="report-result-title">타인 사고 상황 수용 능력 검사 결과</div>
              <div className="report-graph">
                <canvas ref={chartRef2} className="chart-canvas" width="400"></canvas>
              </div>
              <div className="report-legend">
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#FFE3A8' }}></div>
                  <div className="legend-label">종합</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#66DFB8' }}></div>
                  <div className="legend-label">긍정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#72C0FF' }}></div>
                  <div className="legend-label">행동 유발 환경 영역</div>
                </div>
              </div>
            </div>
            <div className="report-class">
              <span>31 ~ 52 낮은 수준 / </span>
              <span>53 ~ 80 보통 수준 / </span>
              <span>81 ~ 100 높은 수준 </span>
            </div>
            <div className="report-section table-section">
              <div className="report-table result-report">
                <div className="box_text">
                  <span>아동의 타인 사고 생활 수용 능력은</span>
                  <span className="bg_text">낮은 상태</span>
                  <span>입니다.</span>
                </div>
                <div className="desc_box">
                  에 대해 측정합니다. 일종의 강박 행동인 교조증은 스트레스 상황이나 부정적인 감정을 해소하기 위해 손톱을
                  물어뜯음으로써 스트레스 완화, 긴장 및 불안 감소 등 긍정적인 정서가 일시적으로 유발되는 것을 돕습니다.
                  하지만 신체를 손상하는 부적절한 행동에 따른 결과이므로, 고착되는 것을 예방하는 노력이
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw03"></div>
      </div>
    </div>
  );
}

export default ReportDetailD1;
