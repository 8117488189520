import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import { Link, useLocation } from "react-router-dom";
import { PACKAGE_TEST_URL } from "../../utils/urls";
import { instance } from "../../services/services";

function CallSinglePayment(){
    const location = useLocation();
    const { goodsSeq, packageCnt, category } = location.state || {};
    const [packageData, setPackageData] = useState(null);


    useEffect(() => {
        const fetchPackageData = async () => {
            try {
                const response = await instance.post(PACKAGE_TEST_URL, {
                    goods_seq: goodsSeq
                });

                if (response.data.code === 200) {
                    setPackageData(response.data.data);
                } else {
                    console.error('패키지 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('패키지 데이터 조회 오류:', error);
            }
        };

        if (goodsSeq) {
            fetchPackageData();
        }
    }, [goodsSeq]);


    const handleBackClick = () => {
        window.history.back();
    };

    // 이미지를 2개씩 그룹화하는 함수
    const groupImages = (items) => {
        const result = [];
        for (let i = 0; i < items.length; i += 2) {
            result.push(items.slice(i, i + 2));
        }
        return result;
    };

    return(
        <div className="CallSinglePayment">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick} />
                <div className="intro_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-60" badgeText="동화" />
                        <div className="intro_age_group">{packageData?.goodsInfo.goods_name}</div> 
                        <div className="assessment_meta group_line" style={{gap:"0px"}}>
                            <div className="assessment_code">{category}</div>
                            <span className="group_item"/>
                            <div className="assessment_question_count">{packageCnt}권</div>
                        </div>
                    </div> 
                </div>

                <div className="payment_body">
                    <div className="CallSinglePayment-instruction">구매 원하시는 동화 검사를 선택해주세요</div>
                    <div className="CallSinglePayment-selection">
                        <Link 
                            to="/seriespayment" 
                            style={{textDecoration:"none"}}
                            state={{ 
                                goodsSeq: goodsSeq,
                                packageCnt: packageCnt,
                                category: category, 
                            }}
                        >
                            <div className="CallSinglePayment-series">
                                <div className="CallSinglePayment-series-title">{packageData?.goodsInfo.goods_name} 시리즈</div>
                                <div className="CallSinglePayment-select-all">{packageCnt}권 전체 선택</div>
                            </div>
                        </Link>
                        <div className="CallSinglePayment-images">
                            {packageData?.packageGoods && 
                                groupImages(packageData.packageGoods).map((group, groupIndex) => (
                                    <div key={groupIndex} className="CallSinglePayment-image-row">
                                        {group.map((item) => (
                                            <Link
                                                key={item.goods_seq} 
                                                to="/seriesSinglepayment"
                                                state={{ goodsSeq: item.goods_seq }}
                                            >
                                                <div className="CallSinglePayment-image">
                                                    <img 
                                                        style={{borderRadius:"32px"}}
                                                        src={item.mainImage} 
                                                        className="Payment_img" 
                                                        alt={item.goods_name}
                                                    />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CallSinglePayment;