import React, {useState, useEffect, useMemo} from "react";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Check_Img from "../../assets/img/shooping_check.png";
import Shooping_click from "../../assets/img/shooping_click.png";
import { CART_LIST_URL, TOSS_PAYMENT_URL } from "../../utils/urls";
import { instance } from "../../services/services";
import { loadTossPayments } from '@tosspayments/payment-sdk';
import {useLocation, useNavigate} from "react-router-dom";
import { setCookie, getCookie } from "../../utils/cookies";
import useUserStore from '../../store/user/userStore';

function ShoppingCart() {
    const [cartItems, setCartItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [isLoading, setIsLoading] = useState(false);
    const { memberTokens, updateTokens } = useUserStore();
    console.log(cartItems, '::cartItems');

    // 장바구니 목록 조회
    useEffect(() => {
        fetchCartList();
    }, []);

    const fetchCartList = async () => {
        try {
            const response = await instance.post(CART_LIST_URL, {});
            console.log('장바구니 조회 응답:', response.data);
            
            if (response.data.success) {
                if (response.data.data?.cartList?.length > 0) {
                    const cartData = response.data.data.cartList.map(item => ({
                        ...item,
                        isChecked: false
                    }));
                    setCartItems(cartData);
                } else {
                    setCartItems([]);
                }
            } else if (response.data.code === -200) {
                setCartItems([]);
            }
        } catch (error) {
            console.error('장바구니 목록 조회 실패:', error);
        }
    };

    // 개별 상품 선택/해제
    const handleCheck = (cartOptionSeq) => {
        setCartItems(prevItems => 
            prevItems.map(item => 
                item.cart_option_seq === cartOptionSeq 
                    ? { ...item, isChecked: !item.isChecked } 
                    : item
            )
        );
    };

    // 전체 선택/해제
    const handleSelectAll = () => {
        const newCheckedState = !isAllChecked;
        setIsAllChecked(newCheckedState);
        setCartItems(prevItems => 
            prevItems.map(item => ({ ...item, isChecked: newCheckedState }))
        );
    };

    // 선택 상품 삭제
    const handleDelete = async () => {
        const selectedItems = cartItems.filter(item => item.isChecked);
        if (selectedItems.length === 0) {
            alert('삭제할 상품을 선택해주세요.');
            return;
        }

        try {
            const requestBody = {
                cart_change_goods: selectedItems.map(item => ({
                    cart_option_seq: item.cart_option_seq.toString(),
                    ea: "1",
                    delete_flag: "Y"
                }))
            };
            
            console.log('삭제 요청 데이터:', requestBody);
            const response = await instance.post(CART_LIST_URL, requestBody);
            console.log('삭제 응답 데이터:', response.data);

            if (response.data.success || response.data.code === -200) {
                // 삭제 전 장바구니에 있던 전체 상품 수
                const totalItemsBeforeDelete = cartItems.length;
                // 선택된 상품 수
                const selectedItemCount = selectedItems.length;

                // 삭제 후 장바구니 상태 업데이트
                if (response.data.data?.cartList?.length > 0) {
                    const updatedCartData = response.data.data.cartList.map(item => ({
                        ...item,
                        isChecked: false
                    }));
                    setCartItems(updatedCartData);
                } else {
                    setCartItems([]);
                }
                setIsAllChecked(false);

                // alert 메시지 결정
                if (selectedItemCount === 1) {
                    // 단일 상품 선택한 경우
                    alert('선택한 상품이 삭제되었습니다.');
                } else if (isAllChecked && totalItemsBeforeDelete > 1) {
                    // 전체 선택으로 여러 상품을 삭제한 경우
                    alert('선택한 상품들이 모두 삭제되었습니다.');
                } else {
                    // 여러 상품을 선택한 경우
                    alert('선택한 상품들이 삭제되었습니다.');
                }
            } else {
                console.error('삭제 실패:', response.data);
                alert(response.data.message || '삭제에 실패했습니다.');
            }

            // 삭제 후 장바구니 목록 다시 조회
            await fetchCartList();

        } catch (error) {
            console.error('삭제 처리 중 에러:', error);
            alert('상품 삭제 중 오류가 발생했습니다.');
        }
    };

    // 구매하기
    const handleBuy = async () => {
        try {
            setIsLoading(true);
            const selectedItems = cartItems.filter(item => item.isChecked);
            if (selectedItems.length === 0) {
                alert('구매할 상품을 선택해주세요.');
                return;
            }

            const token = localStorage.getItem('accessToken') || memberTokens || getCookie('access_token');

            if (!token) {
                console.error('토큰이 없습니다');
                navigate('/login', { 
                    state: { 
                        returnPath: '/shoppingcart'
                    } 
                });
                return;
            }

            // 토큰 저장소 동기화
            localStorage.setItem('accessToken', token);
            sessionStorage.setItem('accessToken', token);
            setCookie('access_token', token, 30);
            updateTokens(token);

            // 결제 전 토큰 백업
            localStorage.setItem('payment_backup_token', token);
            sessionStorage.setItem('payment_backup_token', token);
            setCookie('payment_backup_token', token, 1);

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: selectedItems.reduce((total, item) => total + Number(item.price), 0),
                order_kind: "story",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: selectedItems.map(item => ({
                    goods_seq: item.goods_seq,
                    option_seq: item.option_seq,
                    ea: item.ea
                })),
                validation_data: {
                    cart_items: selectedItems.map(item => ({
                        cart_option_seq: item.cart_option_seq,
                        goods_name: item.goods_name,
                        original_price: item.price,
                        inspection_title: item.inspection_title
                    }))
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.data.code === 200 && response.data.data?.data) {
                const paymentInfo = response.data.data.data;
                const clientKey = 'live_ck_DLJOpm5QrlyM1Babwl2A8PNdxbWn';
                const baseUrl = 'https://iqvitamininside.com';

                // 선택된 상품들의 goods_seq를 쿼리 파라미터로 구성
                const selectedGoodsSeqs = selectedItems
                    .map(item => item.goods_seq)
                    .join(',');

                const tossPayments = await loadTossPayments(clientKey);
                await tossPayments.requestPayment('카드', {
                    amount: paymentInfo.amount,
                    orderId: paymentInfo.orderId,
                    orderName: paymentInfo.orderName,
                    customerName: paymentInfo.customerName,
                    successUrl: `${baseUrl}/payment/result?goods_seq=${selectedGoodsSeqs}&order_kind=story&redirect_path=/my-list&redirect_params=type=test&backup_token=${encodeURIComponent(token)}`,
                    failUrl: `${baseUrl}/payment/result?fail_redirect_path=/shoppingcart&fail_redirect_params=goods_seq=${selectedGoodsSeqs}&backup_token=${encodeURIComponent(token)}`,
                    windowTarget: 'BLANK'
                });
            } else {
                throw new Error(response.data.message || '결제 요청이 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            if (error.response?.status === 401) {
                alert('로그인이 필요합니다. 다시 로그인해주세요.');
                navigate('/login', { 
                    state: { 
                        returnPath: '/shoppingcart'
                    } 
                });
            } else {
                alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackClick = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    }

    // 장바구니 아이템 렌더링
    const renderCartItem = (item) => (
        <div className="cart_item" key={item.cart_option_seq} onClick={() => handleCheck(item.cart_option_seq)}>
            <div className="item_image">
                <img src={item.goods_image} alt={item.goods_name} className="shopping_img" />
            </div>
            <div className="item_details">
                <div className="item_title">{item.goods_name}</div>
                <div className="item_description">{item.story_title}</div>
                <div className="call_type_details" style={{ paddingTop: "6px" }}>
                    <span className="emotion_behavior">{item.story_title_sub}</span>
                    <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                    <span className="k-cast">{item.goodsCnt}권</span>
                </div>
            </div>
            <div className="item_check">
                <img 
                    src={item.isChecked ? Shooping_click : Check_Img} 
                    alt="Check_Img" 
                    className="check-img" 
                />
            </div>
        </div>
    );

    return (
        <div className="ShoppingCart">
            <div className="content">
                <Header title="장바구니" handleBack={handleBackClick} />
                <div className="content_body">
                    <div className="shopping_cart_options">
                        <div className="remove-item" onClick={handleDelete}>삭제하기</div>
                        <div className="select-all" onClick={handleSelectAll}>전체선택</div>
                    </div>

                    <div className="cart_info">
                        <div className="cart-info-item">구매하신 동화는 무한 재생 가능합니다.</div>
                        <div className="cart-info-item">동화 리포트는 3회까지만 제공됩니다.</div>
                    </div>

                    {cartItems.map(renderCartItem)}
                </div>

                <div className="Edit_btn_change">
                    <Button 
                        label="바로 구매하기" 
                        variant="btn_complete" 
                        onClick={handleBuy} 
                        disabled={!cartItems.some(item => item.isChecked) || isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default ShoppingCart;
